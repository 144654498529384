import config from "../config";
import useFetch from "./useFetch";

const useUser = (id) => {
  const { data, loading, error, refresh } = useFetch(
    `${config.fingeniousApiUrl}/user/${id}`
  );

  return {
    data,
    loading,
    error,
    refresh,
  };
};

export default useUser;
