import Container from "react-bootstrap/Container";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Title.module.scss";

const Title = ({ children, rightChildren }) => (
  <Container className={styles.titleContainer}>
    <h1 className={styles.title}>{children}</h1>
    <div className={styles.rightChildren}>{rightChildren}</div>
  </Container>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  rightChildren: PropTypes.node,
};

export default Title;
