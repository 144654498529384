import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SurveyList from "../../components/SurveyList/SurveyList";
import { useSelector } from "react-redux";
import Title from "../../components/Title/Title";
import { isAdmin } from "../../helpers/session";

const SurveyListPage = ({ match }) => {
  const userData = useSelector((state) => state.user.data);
  const userId = userData.userId;
  const roles = userData.roles;
  const { t } = useTranslation();

  const buttons = isAdmin(roles) ? (
    <Link to={"/surveys/new"}>
      <button type="button" className={"button"}>
        + {t("surveylist_new_survey")}
      </button>
    </Link>
  ) : null;

  return (
    <div className="App page-user-surveylist">
      <Title rightChildren={buttons}>{t("surveys")}</Title>
      <SurveyList userId={userId} />
    </div>
  );
};

export default SurveyListPage;
