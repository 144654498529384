import { CHANGE_LANGUAGE } from "./i18n.actions";
import i18n from "../i18n";

export const doChangeLanguage = function (lng) {
  return {
    type: CHANGE_LANGUAGE,
    lng,
  };
};

export const changeLanguage = function (lng) {
  return (dispatch) => {
    dispatch(doChangeLanguage(lng));
    i18n.changeLanguage(lng);
  };
};
