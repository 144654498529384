import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CheckBox from "../CheckBox";
import Radio from "../RadioButton";
import { useTranslation } from "react-i18next";
import CustomFormControl from "../CustomFormControl";
import CustomFormLabel from "../CustomFormLabel";
import {
  SurveyEditSchemaAdmin,
  SurveyEditSchemaResearcher,
} from "./SurveyEditSchema";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { createSurvey, updateSurvey } from "../../actions/survey";
import { hasServerError } from "../../helpers/serverErrorHelper";
import ErrorBox from "../ErrorBox";
import { filterInitialValues } from "./surveyState";
import DatePickerFormControl from "../DatePickerFormControl/DatePickerFormControl";
import useUserRoles from "../../hooks/useUserRoles";
import WYSIWYGTextarea from "../WYSIWYGTextarea/WYSIWYGTextarea";
import { FormLabel } from "react-bootstrap";
import Select from "../Select";
import {
  REDCAP_LABEL,
  REDCAP_VALUE,
  SURVEY_GIZMO_LABEL,
  SURVEY_GIZMO_VALUE,
} from "../../helpers/constants";

const SurveyEditForm = ({ surveyId, initialValues }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errorCode } = useSelector((state) => state.forms.updateProfile);
  const { isAdmin, isResearcher } = useUserRoles();

  const filteredInitialValues = filterInitialValues(initialValues);

  const formikInitialValues = {
    published: "0",
    title: "",
    survey_platform: SURVEY_GIZMO_VALUE,
    biobanks: [],
    title_fi: "",
    title_sv: "",
    start_date: "",
    end_date: "",
    description: "",
    description_fi: "",
    description_sv: "",
    project_number: "",
    intro: "",
    intro_fi: "",
    intro_sv: "",
    thankyou: "",
    thankyou_fi: "",
    thankyou_sv: "",
    survey_url: "",
    survey_url_fi: "",
    survey_url_sv: "",
    studyid: "",
    ...filteredInitialValues,
  };

  // clean out redundant fields
  if (isResearcher()) {
    delete formikInitialValues.published;
    delete formikInitialValues.biobanks;
    delete formikInitialValues.studyid;
  }

  const schema = isAdmin()
    ? SurveyEditSchemaAdmin
    : SurveyEditSchemaResearcher(initialValues.type);

  // Should always be kept in sync with biobank-admin-api/app/src/Helper/SurveyPlatform
  const supportedSurveyPlatform = [
    {
      label: SURVEY_GIZMO_LABEL,
      value: SURVEY_GIZMO_VALUE,
    },
    {
      label: REDCAP_LABEL,
      value: REDCAP_VALUE,
    },
  ];

  const SurveyEditLabel = (props) => (
    <CustomFormLabel validationSchema={schema} {...props} />
  );

  const SurveyEditFormControl = (props) => (
    <CustomFormControl {...props} validationSchema={schema} />
  );

  const SurveyEditCheckBox = (props) => (
    <CheckBox {...props} validationSchema={schema} />
  );

  const SurveyEditRadio = (props) => (
    <Radio {...props} validationSchema={schema} />
  );

  const SurveyEditWYSIWYG = (props) => (
    <Form.Row>
      <Form.Group as={Col} controlId="">
        <SurveyEditLabel fieldName={props.name}>{props.label}</SurveyEditLabel>
        <WYSIWYGTextarea {...props} />
      </Form.Group>
    </Form.Row>
  );

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formikInitialValues}
        validationSchema={schema}
        onSubmit={(values) => {
          dispatch(
            surveyId ? updateSurvey(surveyId, values) : createSurvey(values)
          );
        }}
      >
        {(formProps) => (
          <Form onSubmit={formProps.handleSubmit}>
            <Container>
              {hasServerError(errorCode) && (
                <ErrorBox msg={t(`surveyedit_form_error_${errorCode}`)} />
              )}
              <Row>
                <Col lg={4}>
                  <h2>{t("surveyedit_form_heading_basic_information")}</h2>
                  <p className={"section-description"}>
                    {t("surveyedit_form_desc_basic_information")}
                  </p>
                </Col>
                <Col lg={8}>
                  {isAdmin() && (
                    <>
                      <Form.Group id="formPublished">
                        <div>
                          <SurveyEditLabel
                            withErrorIndicator
                            formProps={formProps}
                            fieldName={"published"}
                          >
                            {t("surveyedit_form_is_published")}
                          </SurveyEditLabel>
                        </div>

                        <SurveyEditRadio
                          label={t("yes")}
                          name={"published"}
                          value={"1"}
                          inline
                        />
                        <SurveyEditRadio
                          label={t("no")}
                          name={"published"}
                          value={"0"}
                          inline
                        />
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formStudyId">
                          <SurveyEditFormControl
                            name="studyid"
                            type="text"
                            label={t("surveyedit_form_label_studyid")}
                          />
                        </Form.Group>
                      </Form.Row>
                    </>
                  )}
                  <Form.Row>
                    <FormLabel>{`${t(
                      "surveyedit_form_label_platform"
                    )}`}</FormLabel>
                    <Form.Group as={Col} controlId="formSurveyPlatform">
                      <Select
                        name="survey_platform"
                        options={supportedSurveyPlatform}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formTitle">
                      <SurveyEditFormControl
                        name="title"
                        type="text"
                        label={`${t("surveyedit_form_label_title")} (en)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formDescription">
                      <SurveyEditFormControl
                        as={"textarea"}
                        name="description"
                        type="text"
                        label={`${t("surveyedit_form_label_description")} (en)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formStartDate">
                      <div>
                        <SurveyEditLabel
                          withErrorIndicator
                          formProps={formProps}
                          fieldName={"start_date"}
                        >
                          {t("surveyedit_form_label_start_date")}
                        </SurveyEditLabel>
                      </div>

                      <DatePickerFormControl
                        name="start_date"
                        placeholderText={t("surveyedit_form_select_date")}
                        dateFormat="yyyy-MM-dd"
                        // timeFormat="HH:mm"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formEndDate">
                      <div>
                        <SurveyEditLabel
                          withErrorIndicator
                          formProps={formProps}
                          fieldName={"end_date"}
                        >
                          {t("surveyedit_form_label_end_date")}
                        </SurveyEditLabel>
                      </div>
                      <DatePickerFormControl
                        name="end_date"
                        placeholderText={t("surveyedit_form_select_date")}
                        dateFormat="yyyy-MM-dd"
                      />
                    </Form.Group>
                  </Form.Row>
                  {isAdmin() && (
                    <Form.Row>
                      <Form.Group as={Col} controlId="formProjectNumber">
                        <SurveyEditFormControl
                          name="project_number"
                          type="text"
                          label={t("surveyedit_form_label_project_number")}
                        />
                      </Form.Group>
                    </Form.Row>
                  )}
                  {isAdmin() && (
                    <Form.Group id="formBiobanks" controlId={"formBiobanks"}>
                      <SurveyEditLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"formBiobanks"}
                      >
                        {t("surveyedit_choose_biobanks")}
                      </SurveyEditLabel>
                      <SurveyEditCheckBox
                        label={t("auria_biobank")}
                        name={"biobanks"}
                        value={"10"}
                      />
                      <SurveyEditCheckBox
                        label={t("helsinki_biobank")}
                        name={"biobanks"}
                        value={"20"}
                      />
                      <SurveyEditCheckBox
                        label={t("biobank_of_eastern_finland")}
                        name={"biobanks"}
                        value={"30"}
                      />
                      <SurveyEditCheckBox
                        label={t("central_finland_biobank")}
                        name={"biobanks"}
                        value={"40"}
                      />
                      <SurveyEditCheckBox
                        label={t("northern_finland_biobank_borealis")}
                        name={"biobanks"}
                        value={"50"}
                      />
                      <SurveyEditCheckBox
                        label={t("tampere_biobank")}
                        name={"biobanks"}
                        value={"60"}
                      />
                      <SurveyEditCheckBox
                        label={t("thl_biobank")}
                        name={"biobanks"}
                        value={"80"}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <HorizontalLine className="double-vertical-margin" />
              <Row>
                <Col lg={4}>
                  <h2>{t("surveyedit_form_heading_external_survey")}</h2>
                  <p className={"section-description"}>
                    {t("surveyedit_form_desc_external_survey")}
                  </p>
                </Col>
                <Col lg={8}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formSurveyUrl">
                      <SurveyEditFormControl
                        name="survey_url"
                        type="text"
                        label={`${t("surveyedit_form_label_survey_url")} (en)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formSurveyUrlFi">
                      <SurveyEditFormControl
                        name="survey_url_fi"
                        type="text"
                        label={`${t("surveyedit_form_label_survey_url")} (fi)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formSurveyUrlSv">
                      <SurveyEditFormControl
                        name="survey_url_sv"
                        type="text"
                        label={`${t("surveyedit_form_label_survey_url")} (sv)`}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Row>
              <HorizontalLine className="double-vertical-margin" />
              <Row>
                <Col lg={4}>
                  <h2>
                    {t("surveyedit_form_heading_user_facing_information")}
                  </h2>
                  <p className={"section-description"}>
                    {t("surveyedit_form_desc_user_facing_information")}
                  </p>
                </Col>
                <Col lg={8}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formIntro">
                      <SurveyEditWYSIWYG
                        initialValue={formikInitialValues.intro}
                        label={`${t("surveyedit_form_label_intro")} (en)`}
                        name="intro"
                        onChange={formProps.setFieldValue}
                        error={formProps.errors.intro}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formThankou">
                      <SurveyEditWYSIWYG
                        initialValue={formikInitialValues.thankyou}
                        name="thankyou"
                        label={`${t("surveyedit_form_label_thankyou")} (en)`}
                        onChange={formProps.setFieldValue}
                        error={formProps.errors.thankyou}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Row>
              <HorizontalLine className="double-vertical-margin" />
              <Row>
                <Col lg={4}>
                  <h2>{t("surveyedit_form_heading_translations")}</h2>
                  <p className={"section-description"}>
                    {t("surveyedit_form_desc_translations_information")}
                  </p>
                </Col>
                <Col lg={8}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formTitleFi">
                      <SurveyEditFormControl
                        name="title_fi"
                        type="text"
                        label={t("surveyedit_form_label_title") + ` (fi)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formTitleSv">
                      <SurveyEditFormControl
                        name="title_sv"
                        type="text"
                        label={t("surveyedit_form_label_title") + ` (sv)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <HorizontalLine />
                  <Form.Row>
                    <Form.Group as={Col} controlId="formDescriptionFi">
                      <SurveyEditFormControl
                        as={"textarea"}
                        name="description_fi"
                        type="text"
                        label={t("surveyedit_form_label_description") + ` (fi)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formDescriptionSv">
                      <SurveyEditFormControl
                        as={"textarea"}
                        name="description_sv"
                        type="text"
                        label={t("surveyedit_form_label_description") + ` (sv)`}
                      />
                    </Form.Group>
                  </Form.Row>
                  <HorizontalLine />
                  <Form.Row>
                    <Form.Group as={Col} controlId="formIntroFi">
                      <SurveyEditWYSIWYG
                        initialValue={formikInitialValues.intro_fi}
                        name="intro_fi"
                        label={t("surveyedit_form_label_intro") + ` (fi)`}
                        onChange={formProps.setFieldValue}
                        error={formProps.errors.intro_fi}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formIntroSv">
                      <SurveyEditWYSIWYG
                        initialValue={formikInitialValues.intro_sv}
                        name="intro_sv"
                        label={t("surveyedit_form_label_intro") + ` (sv)`}
                        onChange={formProps.setFieldValue}
                        error={formProps.errors.intro_sv}
                      />
                    </Form.Group>
                  </Form.Row>
                  <HorizontalLine />
                  <Form.Row>
                    <Form.Group as={Col} controlId="formThankouFi">
                      <SurveyEditWYSIWYG
                        initialValue={formikInitialValues.thankyou_fi}
                        name="thankyou_fi"
                        label={t("surveyedit_form_label_thankyou") + ` (fi)`}
                        onChange={formProps.setFieldValue}
                        error={formProps.errors.thankyou_fi}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formThankouSv">
                      <SurveyEditWYSIWYG
                        initialValue={formikInitialValues.thankyou_sv}
                        name="thankyou_sv"
                        label={t("surveyedit_form_label_thankyou") + ` (sv)`}
                        onChange={formProps.setFieldValue}
                        error={formProps.errors.thankyou_sv}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Row>
            </Container>

            <HorizontalLine className={"top-margin"} />
            {
              <Container>
                <Row>
                  <Col lg={{ span: 8, offset: 4 }}>
                    <div className={"button-group"}>
                      <button
                        disabled={formProps.isSubmitting}
                        className={"button"}
                        type="submit"
                        // onClick={() => {
                        //   formProps.setFieldValue(
                        //     "submitForApprovalClicked",
                        //     true,
                        //     false
                        //   );
                        //   formProps.handleSubmit();
                        // }}
                      >
                        {t("surveyedit_form_submit")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

SurveyEditForm.propTypes = {
  surveyId: PropTypes.number,
  initialValues: PropTypes.object,
};

export default SurveyEditForm;
