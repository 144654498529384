// creating new survey
export const SURVEY_CREATE = "SURVEY_CREATE";
export const SURVEY_CREATE_SUCCESSFUL = "SURVEY_CREATE_SUCCESSFUL";
export const SURVEY_CREATE_FAILURE = "SURVEY_CREATE_FAILURE";

// updating existing survey
export const SURVEY_UPDATE = "SURVEY_UPDATE";
export const SURVEY_UPDATE_SUCCESSFUL = "SURVEY_UPDATE_SUCCESSFUL";
export const SURVEY_UPDATE_FAILURE = "SURVEY_UPDATE_FAILURE";

export const SURVEY_RESET_FORM_STATE = "SURVEY_RESET_FORM_STATE";
