import React from "react";

import EditProfile from "../components/EditProfile/EditProfile";
import useUser from "../hooks/useUser";
import { useSelector } from "react-redux";

const EditProfilePage = ({ match, history }) => {
  const userId = useSelector((state) => state.user.data.userId);
  const { data, loading, error } = useUser(userId);

  return (
    <div className="App page-user-editprofile">
      <EditProfile
        loading={loading}
        loadingError={error}
        userId={userId}
        initialValues={data}
        history={history}
      />
    </div>
  );
};

export default EditProfilePage;
