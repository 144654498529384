import axios from "../helpers/axios";
import config from "../config";
import notify from "../helpers/notification";
import { logError } from "../helpers/logger";
import { replace } from "connected-react-router";
import { getSurveyListUrl } from "../helpers/linkGenerator";

import {
  SURVEY_CREATE,
  SURVEY_CREATE_SUCCESSFUL,
  SURVEY_CREATE_FAILURE,
  SURVEY_UPDATE,
  SURVEY_UPDATE_SUCCESSFUL,
  SURVEY_UPDATE_FAILURE,
  SURVEY_RESET_FORM_STATE,
} from "./survey.actions";

const surveyApiUrl = `${config.fingeniousApiUrl}/survey`;

const notifyCreated = () => notify("saved", "surveyedit_form_created_desc");
const notifyUpdated = () => notify("saved", "surveyedit_form_updated_desc");

// generic stuff
const handleChange = (dispatch, actionCreators, notifyFunction, redirectTo) => {
  actionCreators.map((action) => dispatch(action()));
  setTimeout(() => {
    dispatch(resetFormState());
  }, 3000);
  dispatch(replace(redirectTo()));
  notifyFunction();
};

const resetFormState = () => ({
  type: SURVEY_RESET_FORM_STATE,
});

// Survey creation
export const surveyCreate = () => ({
  type: SURVEY_CREATE,
});

export const surveyCreateSuccessful = () => ({
  type: SURVEY_CREATE_SUCCESSFUL,
});

export const surveyCreateFailure = (code, message) => ({
  type: SURVEY_CREATE_FAILURE,
  code,
  message,
});

export const createSurvey = (fields) => {
  return async (dispatch) => {
    dispatch(surveyCreate());

    try {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(surveyApiUrl, formData, {
        credentials: "include",
      });
      if (response.status === 200) {
        handleChange(
          dispatch,
          [surveyCreateSuccessful],
          notifyCreated,
          getSurveyListUrl
        );

        // notifyCreated();
        // dispatch(surveyCreateSuccessful());
        // setTimeout(() => {
        //   dispatch(resetFormState());
        // }, 3000);
        // dispatch(replace(getSurveyListUrl()));
      }
    } catch (error) {
      logError(error);
      dispatch(
        surveyCreateFailure(error.response.status, error.response.data.message)
      );
    }
  };
};

// Survey update
export const surveyUpdate = () => ({
  type: SURVEY_UPDATE,
});

export const surveyUpdateSuccessful = () => ({
  type: SURVEY_UPDATE_SUCCESSFUL,
});

export const surveyUpdateFailure = (code, message) => ({
  type: SURVEY_UPDATE_FAILURE,
  code,
  message,
});

export const updateSurvey = (surveyId, fields) => {
  return async (dispatch) => {
    dispatch(surveyUpdate());

    try {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(
        surveyApiUrl + `/${surveyId}`,
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        handleChange(
          dispatch,
          [surveyUpdateSuccessful],
          notifyUpdated,
          getSurveyListUrl
        );

        // notifyUpdated();
        // dispatch(surveyUpdateSuccessful());
        // setTimeout(() => {
        //   dispatch(resetFormState());
        // }, 3000);
        // dispatch(replace(getSurveyListUrl()));
      }
    } catch (error) {
      logError(error);
      dispatch(
        surveyUpdateFailure(error.response.status, error.response.data.message)
      );
    }
  };
};
