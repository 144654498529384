import React from "react";

import SurveyEditForm from "../../components/SurveyEdit/SurveyEditForm";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";

const SurveyAddPage = () => {
  return (
    <div className="App page-user-surveylist">
      <Title>TRANSLATION SURVEY EDIT</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <SurveyEditForm initialValues={{}} />
    </div>
  );
};

export default SurveyAddPage;
