import { Field } from "formik";
import Form from "react-bootstrap/Form";
import React from "react";
import { isRequiredField } from "../helpers/formHelper";
import PropTypes from "prop-types";

const Radio = ({ validationSchema, ...props }) => {
  const { name } = props;
  return (
    <Field name={name}>
      {({ field, form }) => (
        <Form.Check
          id={`${name}${props.value}`}
          type="radio"
          {...props}
          checked={field.value && field.value.includes(props.value)}
          onChange={() => {
            if (field.value && field.value.includes(props.value)) {
              const nextValue = field.value.filter(
                (value) => value !== props.value
              );
              form.setFieldValue(name, nextValue);
            } else {
              form.setFieldValue(name, [props.value]);
            }
          }}
          required={isRequiredField(validationSchema, name)}
        />
      )}
    </Field>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Radio;
