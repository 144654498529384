import { useState } from "react";
import Papa from "papaparse";

import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";
import createDownload from "../helpers/fileDownloader";

const surveyApiUrl = `${config.fingeniousApiUrl}/survey`;

const uploadCSVFile = async (surveyId, file) => {
  try {
    const formData = new FormData();
    formData.append("csv", file);

    const response = await axios.post(
      `${surveyApiUrl}/${surveyId}/results`,
      formData,
      {
        credentials: "include",
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    logError(error);
    console.error(error);
  }
};

const basicParse = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      worker: true,
      skipEmptyLines: true,
      complete: function (results) {
        if (!results) {
          return reject();
        }
        resolve(results);
      },
    });
  });
};

const useImportResults = (surveyId) => {
  const [started, setStarted] = useState(false);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const doImport = async (file) => {
    setStarted(true);

    const csvResults = await basicParse(file);

    const total = csvResults.data.length;
    setTotal(total);

    const results = await uploadCSVFile(surveyId, file);
    try {
      const csv = Papa.unparse(results.data);
      createDownload(csv);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  return {
    doImport,
    started,
    total,
    // ended,
    error,
  };
};

export default useImportResults;
