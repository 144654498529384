import Yup from "../../Yup";
import {
  address,
  commonRequiredContactInfoFields,
  email,
  langId,
  organizationType,
  password,
} from "../../helpers/formSchemaHelper";

const RegisterSchema = Yup.object().shape({
  email: email,
  password: password.required(),
  confirmpassword: password
    .when("password", {
      is: (val) => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref("password")], {
          key: "forms.validation.password.must_match",
        })
        .required(),
    })
    .required(),
  ...commonRequiredContactInfoFields,
  organizationtype: organizationType,
  address: address,
  langid: langId,
});

export default RegisterSchema;
