import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import styles from "./WYSIWYGTextarea.module.scss";
import config from "../../config";

const plugins = [
  "advlist autolink lists link charmap preview anchor image",
  "searchreplace visualblocks fullscreen",
  "insertdatetime paste help wordcount",
  "hr", // NOTE: used as page break
];

const toolbar =
  "undo redo | bold italic underline strikethrough superscript | link image | bullist numlist | removeformat | hr | help";

const WYSIWYGTextarea = ({ name, initialValue = "", onChange, error = "" }) => {
  const onEditorStateChange = (content) => {
    onChange(name, content);
  };

  const hasError = error && error.length;

  const editorClassNames = ["form-control", styles.wysiwyg];
  if (hasError) {
    editorClassNames.push("is-invalid");
  }

  return (
    <>
      <Editor
        apiKey={config.tinyMCEApiKey}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: plugins,
          toolbar: toolbar,
        }}
        onEditorChange={onEditorStateChange}
      />
      {hasError && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error}
        </div>
      )}
    </>
  );
};

WYSIWYGTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default WYSIWYGTextarea;
