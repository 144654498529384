import SurveyListPage from "../pages/Surveys/SurveyListPage";
import SurveyEditPage from "../pages/Surveys/SurveyEditPage";
import SurveyAddPage from "../pages/Surveys/SurveyAddPage";
import SurveyAddUserPage from "../pages/Surveys/SurveyAddUserPage";
import SurveyUploadSubjectsPage from "../pages/Surveys/SurveyUploadSubjectsPage";
import SurveyUploadResultsPage from "../pages/Surveys/SurveyUploadResultsPage";

const surveysUrl = "/surveys";

const routes = [
  {
    path: surveysUrl,
    private: true,
    exact: true,
    component: SurveyListPage,
  },
  {
    path: `${surveysUrl}/new`,
    exact: true,
    private: true,
    component: SurveyAddPage,
  },
  {
    path: surveysUrl + "/:surveyId/edit",
    private: true,
    exact: true,
    component: SurveyEditPage,
  },
  {
    path: surveysUrl + "/:surveyId/users",
    private: true,
    exact: true,
    component: SurveyAddUserPage,
  },
  {
    path: surveysUrl + "/:surveyId/biobank_admins",
    private: true,
    exact: true,
    component: SurveyAddUserPage,
  },
  {
    path: surveysUrl + "/:surveyId/subjects",
    private: true,
    exact: true,
    component: SurveyUploadSubjectsPage,
  },
  {
    path: surveysUrl + "/:surveyId/results",
    private: true,
    exact: true,
    component: SurveyUploadResultsPage,
  },
];

export default routes;
