const config = {
  catalogApiUrl:
    process.env.REACT_APP_CATALOG_API_URL || "https://api.test.fingenious.fi",
  fingeniousApiUrl:
    process.env.REACT_APP_FINGENIOUS_API_URL || "http://localhost:3001",
  loginUrl: process.env.REACT_APP_LOGIN_URL || "/user/login",
  fingeniousMain: process.env.REACT_APP_FINGENIOUS_MAIN || "/surveys",
  enableJwtAuth: process.env.REACT_APP_ENABLE_JWT_AUTH || 0,
  amplitudeId: process.env.REACT_APP_AMPLITUDE_ID || null,
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN || null,
  cookiePath: process.env.REACT_APP_COOKIE_PATH || "/",
  secureCookies: process.env.REACT_APP_SECURE_COOKIES || 0,
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || "en",
  appEnv: process.env.REACT_APP_HOST_ENV || "",
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || "",
  tinyMCEApiKey: process.env.REACT_APP_TINYMCE_API_KEY || "",
};

export default config;
