import React from "react";
import { useFormik } from "formik";

import Container from "react-bootstrap/Container";
import useImportResults from "../../hooks/useImportResults";

const SurveyUploadResults = ({ surveyId }) => {
  const formik = useFormik({
    initialValues: {
      csv: "",
    },
    onSubmit: async (values) => {
      doImport(values.csv);
    },
  });

  const { doImport } = useImportResults(surveyId);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <p>Choose file:</p>
        <input
          type="file"
          name="csv"
          accept=".csv"
          onChange={(event) => {
            formik.setFieldValue("csv", event.currentTarget.files[0]);
          }}
        />
        <button type="submit">Submit</button>
      </form>
    </Container>
  );
};

export default SurveyUploadResults;
