import * as Yup from "yup";

import {
  requiredArray,
  requiredText,
  requiredHTML,
} from "../../helpers/formValidators";

const validSurveyUrl = Yup.string()
  .trim()
  .matches(/(testmybrain\.org|surveygizmo\.(com|eu))/, {
    key: "surveyedit_form_error_valid_survey_providers",
  })
  .required();

export const SurveyEditSchemaAdmin = Yup.object().shape({
  published: Yup.boolean(),
  survey_platform: Yup.string().required(),
  title: requiredText,
  start_date: Yup.date().required(),
  end_date: Yup.date().required(),
  description: requiredText,
  biobanks: requiredArray,
  project_number: requiredText,
  studyid: Yup.number().required(),
});

export const SurveyEditSchemaResearcher = (studyType) =>
  Yup.object().shape({
    survey_platform: Yup.string().required(),
    title: requiredText,
    title_fi: requiredText,
    title_sv: requiredText,
    start_date: Yup.date().required(),
    end_date: Yup.date().required(),
    description: requiredText,
    description_fi: requiredText,
    description_sv: requiredText,
    intro: requiredHTML,
    intro_fi: requiredHTML,
    intro_sv: requiredHTML,
    thankyou: requiredHTML,
    thankyou_fi: requiredHTML,
    thankyou_sv: requiredHTML,
    survey_url: studyType === "readonly" ? Yup.string() : validSurveyUrl,
    survey_url_fi: studyType === "readonly" ? Yup.string() : validSurveyUrl,
    survey_url_sv: studyType === "readonly" ? Yup.string() : validSurveyUrl,
  });
