import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CustomFormControl from "../CustomFormControl";
import { isRequiredField } from "../../helpers/formHelper";
import { hasServerError } from "../../helpers/serverErrorHelper";
import ErrorBox from "../ErrorBox";
import HorizontalLine from "../HorizontalLine/HorizontalLine";

import styles from "./verify-2fa.module.scss";
import TwoFASchema from "./2FASchema";
import LoginSchema from "../Login/LoginSchema";
import { verify2FA, enable2FA, disable2FA } from "../../actions/users";

const Verify2FA = ({ formData, refetch, loading }) => {
  const userState = useSelector((state) => state.user);
  const { userId } = userState.data;
  const { qrCode, secret } = userState;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorCode, success: verify2FASuccess } = useSelector(
    (state) => state.forms.verify2FA
  );
  const twoFAEnabled = formData && !!Number(formData["2fa_enabled"]);
  const [showVerify, setShowVerify] = useState(false);
  const [showDisable, setShowDisable] = useState(false);

  const { success: enable2FASuccess, errorCode: enable2FAErrorCode } =
    useSelector((state) => state.forms.enable2FA);

  const { success: disable2FASuccess, errorCode: disable2FAErrorCode } =
    useSelector((state) => state.forms.disable2FA);

  useEffect(() => {
    if (enable2FASuccess) {
      setShowVerify(true);
    }
  }, [enable2FASuccess]);

  useEffect(() => {
    if (verify2FASuccess || disable2FASuccess) {
      refetch();
      setShowVerify(false);
      setShowDisable(false);
    }
    // eslint-disable-next-line
  }, [verify2FASuccess, disable2FASuccess]);

  const currentErrorCode =
    disable2FAErrorCode || enable2FAErrorCode || errorCode;

  return (
    <div className="App page-user-editprofile">
      <Title>{t("2fa")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <div className="finbb-container">
        <p className="section-description">{t("2fa_intro")}</p>
        {!loading && (
          <>
            <p
              className={`section-description ${
                twoFAEnabled && "text-success"
              }`}
            >
              {twoFAEnabled ? t("2fa_enabled_intro") : t("2fa_disabled_intro")}
            </p>
            {!(showVerify || showDisable) && (
              <button
                className={`button button-secondary ${styles.button}`}
                type="button"
                onClick={() =>
                  !twoFAEnabled
                    ? dispatch(enable2FA(userId, formData.email))
                    : setShowDisable(true)
                }
              >
                {twoFAEnabled ? t("2fa_disable") : t("2fa_enable")}
              </button>
            )}
          </>
        )}
        {hasServerError(currentErrorCode) && (
          <ErrorBox msg={t(`2fa_verify_error_${currentErrorCode}`)} />
        )}
        {(showVerify || showDisable) && (
          <div className={styles.verifyForm}>
            <div className={styles.title}>
              <Title>{showVerify ? t("2fa_enable") : t("2fa_disable")}</Title>
            </div>
            <Formik
              initialValues={{
                password: "",
                verification: "",
                email: formData.email,
              }}
              validationSchema={showVerify ? TwoFASchema : LoginSchema}
              onSubmit={(values) =>
                showVerify
                  ? dispatch(
                      verify2FA(
                        userId,
                        formData.email,
                        values.password,
                        secret,
                        values.verification
                      )
                    )
                  : dispatch(
                      disable2FA(userId, formData.email, values.password)
                    )
              }
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  {showVerify && (
                    <>
                      <Row className={styles.row}>
                        <Col lg={4}>
                          <div className={styles.headerContainer}>
                            <span className={styles.stepIndicator}>1</span>
                            <h2>{t("2fa_qrCode_title")}</h2>
                          </div>
                          <p className={"section-description"}>
                            {t("2fa_qrCode_heading")}
                          </p>
                        </Col>
                        <Col lg={6}>
                          {qrCode ? (
                            <img alt="QR code" src={qrCode} />
                          ) : (
                            "No QR code available"
                          )}
                        </Col>
                      </Row>
                      <Row className={styles.row}>
                        <Col lg={4}>
                          <div className={styles.headerContainer}>
                            <span className={styles.stepIndicator}>2</span>
                            <h2>{t("2fa_verification_title")}</h2>
                          </div>
                          <p className={"section-description"}>
                            {t("2fa_verification_heading")}
                          </p>
                        </Col>
                        <Col lg={3}>
                          <Form.Group as={Col} controlId="formVerification">
                            <CustomFormControl
                              type="text"
                              label={t("2fa_verification")}
                              name={"verification"}
                              required={isRequiredField(
                                TwoFASchema,
                                "verification"
                              )}
                              validationSchema={TwoFASchema}
                              minLength={6}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col lg={4}>
                      <div className={styles.headerContainer}>
                        <span className={styles.stepIndicator}>
                          {showDisable ? 1 : 3}
                        </span>
                        <h2>{t("2fa_password_title")}</h2>
                      </div>
                      <p className={"section-description"}>
                        {showDisable
                          ? t("2fa_disable_password_heading")
                          : t("2fa_password_heading")}
                      </p>
                    </Col>
                    <Col lg={6}>
                      <Form.Group as={Col} controlId="formPassword">
                        <CustomFormControl
                          type="password"
                          label={t("login_form_label_password")}
                          name={"password"}
                          required={isRequiredField(TwoFASchema, "password")}
                          validationSchema={TwoFASchema}
                          minLength={6}
                        />
                      </Form.Group>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col lg={{ span: 6, offset: 4 }}>
                      <div className={"button-group"}>
                        <button
                          disabled={isSubmitting}
                          className={"button"}
                          type="button"
                          onClick={handleSubmit}
                        >
                          {showVerify
                            ? t("2fa_verify")
                            : t("2fa_disable_submit")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify2FA;
