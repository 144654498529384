import config from "../config";
import * as Sentry from "@sentry/browser";

const logError = (error) => {
  if (config.appEnv !== "dev") {
    if (!error.isAxiosError) {
      Sentry.captureException(error);
    }

    return;
  }

  console.log(
    "NOTE: The following error will not be visible on the production environment"
  );
  console.error(error);
};

export { logError };
