import Yup from "../../Yup";
import { email } from "../../helpers/formSchemaHelper";

const LoginSchema = Yup.object().shape({
  email: email,
  // NOTE: existing passwords are 6 chars longer, but we are enforcing a new min-length of 8
  password: Yup.string().min(6).max(128).required(),
});

export default LoginSchema;
