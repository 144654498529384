import React from "react";
import { Provider } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import PrivateRoute from "./PrivateRoute";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "../store/configureStore";
import styles from "./app.module.scss";

import StaticRoutes from "../routes/StaticRoutes";
import SurveyRoutes from "../routes/SurveyRoutes";
import UserRoutes from "../routes/UserRoutes";
import ConsentRoutes from "../routes/ConsentRoutes";

import "../App.scss";
import Header from "./Header";
import FooterContainer from "./FooterContainer";
import withIEFallback from "./withIEFallback";
import "react-datepicker/dist/react-datepicker.css";

const handleRoute = (props) => {
  if (props.render) {
    return props.render;
  }

  return props.private ? (
    <PrivateRoute key={props.path} {...props} />
  ) : (
    <Route key={props.path} {...props} />
  );
};

const routes = [
  ...UserRoutes,
  ...StaticRoutes,
  ...SurveyRoutes,
  ...ConsentRoutes,
  {
    path: "/",

    exact: true,

    private: false,

    render: <Redirect key={`default-redirect-route`} to="/user/login" />,
  },
];

const pathWithHeaderAndFooter =
  "/(surveys|user/edit-profile|user/2factor-authentication|static|consents)/";

const { store, persistor } = configureStore();

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        {/* header only visible where necessary */}
        <Route path={pathWithHeaderAndFooter}>
          {(route) => route.match && <Header />}
        </Route>
        <main className={styles.contentWrapper}>
          <Switch>
            {/* Map out all the routes */}
            {routes.map(handleRoute)}
          </Switch>

          {/* footer and cart only visible where necessary */}
          <Route path={pathWithHeaderAndFooter}>
            {(route) => route.match && <FooterContainer />}
          </Route>
        </main>
      </ConnectedRouter>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-center"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        closeOnToastrClick
      />
    </PersistGate>
  </Provider>
);

export default withIEFallback(App);
