import config from "../config";
import useFetch from "./useFetch";
import { transformData } from "./fetchSurveyHelper";

const useFetchSurvey = (id) => {
  const { data, loading, error } = useFetch(
    `${config.fingeniousApiUrl}/survey/${id}`
  );

  let returnedData = data;
  if (data) {
    returnedData = transformData(data);
  }

  return {
    data: returnedData,
    loading,
    error,
  };
};

export default useFetchSurvey;
