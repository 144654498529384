export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESSFUL = "USER_LOGIN_SUCCESSFUL";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_FAILURE_2FA = "USER_LOGIN_FAILURE_2FA";
export const USER_LOGIN_FAILURE_RATE_LIMIT = "USER_LOGIN_FAILURE_RATE_LIMIT";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESSFUL = "USER_REGISTER_SUCCESSFUL";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const USER_REQUEST_PASSWORD_RESET = "USER_REQUEST_PASSWORD_RESET";
export const USER_REQUEST_PASSWORD_RESET_SUCCESSFUL =
  "USER_REQUEST_PASSWORD_RESET_SUCCESSFUL";
export const USER_REQUEST_PASSWORD_RESET_FAILURE =
  "USER_REQUEST_PASSWORD_RESET_FAILURE";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESSFUL =
  "USER_CHANGE_PASSWORD_SUCCESSFUL";
export const USER_CHANGE_PASSWORD_FAILURE = "USER_CHANGE_PASSWORD_FAILURE";

export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";
export const USER_UPDATE_PROFILE_SUCCESSFUL = "USER_UPDATE_PROFILE_SUCCESSFUL";
export const USER_UPDATE_PROFILE_FAILURE = "USER_UPDATE_PROFILE_FAILURE";

export const USER_ENABLE_2FA = "USER_ENABLE_2FA";
export const USER_ENABLE_2FA_SUCCESSFUL = "USER_ENABLE_2FA_SUCCESSFUL";
export const USER_ENABLE_2FA_FAILURE = "USER_ENABLE_2FA_FAILURE";

export const USER_SAVE_2FA_DATA = "USER_SAVE_2FA_DATA";
export const USER_RESET_2FA_DATA = "USER_RESET_2FA_DATA";

export const USER_VERIFY_2FA = "USER_VERIFY_2FA";
export const USER_VERIFY_2FA_SUCCESSFUL = "USER_VERIFY_2FA_SUCCESSFUL";
export const USER_VERIFY_2FA_FAILURE = "USER_VERIFY_2FA_FAILURE";

export const USER_DISABLE_2FA = "USER_DISABLE_2FA";
export const USER_DISABLE_2FA_SUCCESSFUL = "USER_DISABLE_2FA_SUCCESSFUL";
export const USER_DISABLE_2FA_FAILURE = "USER_DISABLE_2FA_FAILURE";
export const USER_CHECK_2FA_ENABLED = "USER_CHECK_2FA_ENABLED";
export const USER_CHECK_2FA_FAILURE = "USER_CHECK_2FA_FAILURE";

export const USER_RESET_FORM_STATE = "USER_RESET_FORM_STATE";
