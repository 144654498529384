import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { fi, enGB } from "date-fns/locale";
import classNames from "classnames";
import styles from "./DatePickerFormControl.module.scss";
import { format } from "date-fns";
registerLocale("fi", fi);
registerLocale("en", enGB);

const mysqlToDate = (val) => {
  // Split timestamp into [ Y, M, D, h, m, s ]
  const t = val.split(/[- :]/);

  // Apply each element to the Date function
  return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
};

const handleDateWeirdness = (val) => {
  if (!val || !val.length) {
    return new Date();
  }

  // firstly count the tokens to see if we have the time
  const tokenCount = val.split(" ").length;

  // we have a full string (date + time)
  if (tokenCount > 1) {
    return mysqlToDate(val);
  }
  // we only have the date
  else {
    return mysqlToDate(val + " 00:00:00");
  }
};

const DatePickerFormControl = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const {
    i18n: { language },
  } = useTranslation();

  const hasError = meta.touched && meta.error;

  const classes = classNames({
    "form-control": true,
    "is-invalid": hasError,
    [styles.datePicker]: true,
  });

  const formatTo = (val) => {
    if (props.dateFormat && props.dateFormat.length && val) {
      return format(val, props.dateFormat);
    }
    return val;
  };

  return (
    <>
      <DatePicker
        locale={language}
        className={classes}
        {...field}
        {...props}
        selected={handleDateWeirdness(field.value)}
        onChange={(val) => {
          setFieldValue(field.name, formatTo(val));
        }}
      />
      {hasError && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {meta.error}
        </div>
      )}
    </>
  );
};

export default DatePickerFormControl;
