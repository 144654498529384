import React from "react";

import useUser from "../hooks/useUser";
import { useSelector } from "react-redux";
import Verify2FA from "../components/Verify2FA/Verify2FA";

const Verify2FAPage = () => {
  const userId = useSelector((state) => state.user.data.userId);
  const { data, refresh, loading } = useUser(userId);

  return <Verify2FA formData={data} refetch={refresh} loading={loading} />;
};

export default Verify2FAPage;
