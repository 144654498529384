import {
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_FAILURE,
  USER_CHANGE_PASSWORD_SUCCESSFUL,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_FAILURE_2FA,
  USER_LOGIN_SUCCESSFUL,
  USER_REGISTER,
  USER_REGISTER_FAILURE,
  USER_REGISTER_SUCCESSFUL,
  USER_REQUEST_PASSWORD_RESET,
  USER_REQUEST_PASSWORD_RESET_FAILURE,
  USER_REQUEST_PASSWORD_RESET_SUCCESSFUL,
  USER_RESET_FORM_STATE,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
  USER_VERIFY_2FA_SUCCESSFUL,
  USER_VERIFY_2FA_FAILURE,
  USER_ENABLE_2FA_SUCCESSFUL,
  USER_ENABLE_2FA_FAILURE,
  USER_DISABLE_2FA_FAILURE,
  USER_DISABLE_2FA_SUCCESSFUL,
} from "../actions/users.actions";

const initialState = {
  login: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  register: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  requestPasswordReset: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  changePassword: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  updateProfile: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  verify2FA: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  enable2FA: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
  disable2FA: {
    errorCode: null,
    errorMessage: null,
    success: false,
  },
};

const appendToState = (key, errorCode, errorMessage, success, extra = {}) => {
  return {
    ...initialState,
    [key]: {
      ...extra,
      errorCode,
      errorMessage,
      success,
    },
  };
};

const reduceSuccess = (key) => appendToState(key, null, null, true);

const reduceFailure = (key, action, extra = {}) =>
  appendToState(key, action.code, action.message, false, extra);

export const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
    case USER_REGISTER:
    case USER_UPDATE_PROFILE:
    case USER_REQUEST_PASSWORD_RESET:
    case USER_CHANGE_PASSWORD:
      return initialState;
    case USER_LOGIN_FAILURE:
    case USER_LOGIN_FAILURE_2FA:
      return reduceFailure("login", action, {
        twoFactorNeeded: action.twoFactorNeeded,
        verificationValid: action.verificationValid,
      });
    case USER_LOGIN_SUCCESSFUL:
      return reduceSuccess("login");
    case USER_REGISTER_FAILURE:
      return reduceFailure("register", action);
    case USER_REGISTER_SUCCESSFUL:
      return reduceSuccess("register");
    case USER_UPDATE_PROFILE_FAILURE:
      return reduceFailure("updateProfile", action);
    case USER_UPDATE_PROFILE_SUCCESSFUL:
      return reduceSuccess("updateProfile");
    case USER_REQUEST_PASSWORD_RESET_SUCCESSFUL:
      return reduceSuccess("requestPasswordReset");
    case USER_REQUEST_PASSWORD_RESET_FAILURE:
      return reduceFailure("requestPasswordReset", action);
    case USER_CHANGE_PASSWORD_SUCCESSFUL:
      return reduceSuccess("changePassword");
    case USER_CHANGE_PASSWORD_FAILURE:
      return reduceFailure("changePassword", action);
    case USER_ENABLE_2FA_SUCCESSFUL:
      return reduceSuccess("enable2FA");
    case USER_ENABLE_2FA_FAILURE:
      return reduceFailure("enable2FA", action);
    case USER_VERIFY_2FA_SUCCESSFUL:
      return reduceSuccess("verify2FA");
    case USER_VERIFY_2FA_FAILURE:
      return reduceFailure("verify2FA", action);
    case USER_DISABLE_2FA_SUCCESSFUL:
      return reduceSuccess("disable2FA");
    case USER_DISABLE_2FA_FAILURE:
      return reduceFailure("disable2FA", action);
    case USER_RESET_FORM_STATE:
      return initialState;
    default:
      return state;
  }
};
