import React from "react";

import SurveyAddUser from "../../components/SurveyAddUser/SurveyAddUser";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import { useTranslation } from "react-i18next";

const SurveyAddUserPage = ({ match }) => {
  const surveyId = parseInt(match.params.surveyId);
  const { t } = useTranslation();
  return (
    <div className="App page-user-surveylist">
      <Title>{t("survey_add_user")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <SurveyAddUser surveyId={surveyId} />
    </div>
  );
};

export default SurveyAddUserPage;
