import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import i18n from "../i18n";
import Papa from "papaparse";

import axios from "../helpers/axios";
import config from "../config";

import { logError } from "../helpers/logger";
import createDownload from "../helpers/fileDownloader";

import {
  CONSENT_GET_CONSENTS,
  CONSENT_CREATE_FAILURE,
} from "./consents.actions";

const consentApiUrl = `${config.fingeniousApiUrl}/consents`;

const consentsGet = () => ({ type: CONSENT_GET_CONSENTS });

const consentsSuccess = (consents) => {
  if (consents.length) {
    const csv = createConsentCSV(consents);
    createDownload(csv, "consents");
  } else {
    toastr.info(i18n.t("consents_info"), i18n.t("consents_consents_not_found"));
  }
};

const consentsFailure = (code, message) => ({
  type: CONSENT_CREATE_FAILURE,
  code,
  message,
});

export const useGetConsents = () => {
  const dispatch = useDispatch();
  const fetchConsents = useCallback(async () => {
    dispatch(consentsGet());
    try {
      const res = await axios.get(consentApiUrl);
      if (res.status === 200) {
        const consents = res.data && res.data.data ? res.data.data : [];
        consentsSuccess(consents);
      }
    } catch (e) {
      if (e.response.status === 403) {
        toastr.error(i18n.t("consents_error_403"));
      }
      logError(e);
      dispatch(consentsFailure(e.response.status, e.response.message));
    }
  }, [dispatch]);

  return fetchConsents;
};

const createConsentCSV = (consents) => {
  return Papa.unparse(consents, {
    columns: ["uuid", "email", "phone", "consented"],
  });
};
