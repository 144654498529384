export const validateImportSubjectCSVRow = (row) => {
  // one field only please
  if (row.length !== 2) {
    return false;
  }

  // skip over entirely empty rows
  if (row[0].length === 0) {
    return true;
  }

  // ssn approx length
  if (row[0].length !== 36) {
    return false;
  }

  return true;
};
