import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./horizontal-line.module.scss";

const HorizontalLine = ({ className }) => (
  <div className={classNames(styles.horizontalLine, className)}></div>
);

HorizontalLine.propTypes = {
  className: PropTypes.string,
};

export default HorizontalLine;
