const validFields = [
  "published",
  "survey_platform",
  "title",
  "title_fi",
  "title_sv",
  "description",
  "description_fi",
  "description_sv",
  "start_date",
  "end_date",
  "project_number",
  "biobanks",
  "survey_url",
  "survey_url_fi",
  "survey_url_sv",
  "intro",
  "intro_fi",
  "intro_sv",
  "thankyou",
  "thankyou_fi",
  "thankyou_sv",
  "studyid",
];

export const filterInitialValues = (values) =>
  Object.keys(values)
    .filter((key) => validFields.includes(key))
    .reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});
