import { combineReducers } from "redux";
import { userReducer } from "../reducers/user";
import { connectRouter } from "connected-react-router";
import { formsReducer } from "../reducers/forms";
import { lng } from "../reducers/i18n";
import { reducer as toastrReducer } from "react-redux-toastr";

const catalogApp = (history) =>
  combineReducers({
    lng,
    router: connectRouter(history),
    user: userReducer,
    forms: formsReducer,
    toastr: toastrReducer,
  });

export default catalogApp;
