import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import useFetchSurveys from "../../hooks/useFetchSurveys";

import LoadingWrapper from "../../components/LoadingWrapper";
import RequestListLoadingIndicator from "../../components/RequestListLoadingIndicator";
import EmptySurveyListPlaceholder from "./EmptySurveyListPlaceholder";

import styles from "../../pages/RequestList/request-list.module.scss";

import {
  getArchivedSurveyListUrl,
  getActiveSurveyListUrl,
} from "../../helpers/linkGenerator";
import SurveyListItemContainer from "./SurveyListItem";

const Surveys = ({ surveys, emptyPlaceholder, type = "active" }) => {
  return surveys && surveys.length > 0 ? (
    surveys.map((survey) => (
      <SurveyListItemContainer key={survey.surveyid} survey={survey} />
    ))
  ) : (
    <EmptySurveyListPlaceholder title={emptyPlaceholder} type={type} />
  );
};

const SurveyList = ({ tabIndex = 0 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    surveys: activeSurveys,
    loading: activeSurveysLoading,
    error: activeSurveysError,
  } = useFetchSurveys();

  const {
    surveys: archivedSurveys,
    loading: archivedSurveysLoading,
    error: archivedSurveysError,
  } = useFetchSurveys(true);

  return (
    <Tabs
      selectedTabClassName={styles.tabSelected}
      defaultIndex={tabIndex}
      onSelect={(tabIndex) =>
        dispatch(
          replace(
            tabIndex === 1
              ? getArchivedSurveyListUrl()
              : getActiveSurveyListUrl()
          )
        )
      }
    >
      <div className={"finbb-container"}>
        <TabList className={styles.tabList}>
          <Tab className={styles.tab}>{t("active")}</Tab>
          <Tab className={styles.tab}>{t("archived")}</Tab>
        </TabList>
      </div>
      <div className={styles.horizontalLineBelowTabList} />
      <div className={"finbb-container"}>
        <TabPanel className={styles.tabPanel}>
          <LoadingWrapper
            isLoading={activeSurveysLoading}
            isInErrorState={activeSurveysError != null}
            errorMsg={t("active_surveys_loading_error")}
            loadingComponent={<RequestListLoadingIndicator />}
          >
            <Surveys surveys={activeSurveys} type="active" />{" "}
          </LoadingWrapper>
        </TabPanel>
        <TabPanel>
          <LoadingWrapper
            isLoading={archivedSurveysLoading}
            isInErrorState={archivedSurveysError != null}
            errorMsg={t("archived_surveys_loading_error")}
            loadingComponent={<RequestListLoadingIndicator />}
          >
            <Surveys surveys={archivedSurveys} type="archived" />
          </LoadingWrapper>
        </TabPanel>
      </div>
    </Tabs>
  );
};
export default SurveyList;
