import PrivacyPolicy, {
  privacyPolicyUrl,
  privacyPolicyUrlPreLogin,
} from "../pages/static/PrivacyPolicy";
import MTACentralTerms, {
  mtaCentralTermsUrl,
} from "../pages/static/MTACentralTerms";
import TermsAndConditions, {
  termsAndConditionsUrl,
  termsAndConditionsUrlPreLogin,
} from "../pages/static/TermsAndConditions";

const routes = [
  {
    path: privacyPolicyUrl,
    private: false,
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: privacyPolicyUrlPreLogin,
    private: false,
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: mtaCentralTermsUrl,
    private: false,
    exact: true,
    component: MTACentralTerms,
  },
  {
    path: termsAndConditionsUrl,
    private: false,
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: termsAndConditionsUrlPreLogin,
    private: false,
    exact: true,
    component: TermsAndConditions,
  },
];

export default routes;
