import { toastr } from "react-redux-toastr";
import i18n from "../i18n";
import Papa from "papaparse";
import axios from "../helpers/axios";
import config from "../config";
import { logError } from "../helpers/logger";
import createDownload from "../helpers/fileDownloader";

export const useGetContactInfo = (surveyId) => {
  const downlodaContacts = async () => {
    try {
      const res = await axios.get(
        `${config.fingeniousApiUrl}/survey/${surveyId}/export`
      );
      if (res.status === 200) {
        const csv = createContactsCSV(res.data?.data || []);
        createDownload(csv, "contacts");
      }
    } catch (e) {
      logError(e);
      toastr.error(i18n.t("login_form_error_500")); // reuse generic(?) error message
    }
  };

  return downlodaContacts;
};

const createContactsCSV = (contacts) => {
  return Papa.unparse(contacts, {
    columns: ["uuid", "name", "phone", "lang", "timestamp"],
  });
};
