import config from "../config";
import useFetch from "./useFetch";

const useFetchSurveys = (isArchived) => {
  const { data, loading, error } = useFetch(
    `${config.fingeniousApiUrl}/survey?deleted=${isArchived ? 1 : 0}`
  );

  return {
    surveys: data,
    loading,
    error,
  };
};

export default useFetchSurveys;
