import ConsentDownload from "../components/ConsentDownload";

const routes = [
  {
    path: "/consents",
    private: true,
    exact: true,
    component: ConsentDownload,
  },
];

export default routes;
