import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Formik, useField } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Title from "../Title/Title";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { useTranslation } from "react-i18next";
import CustomFormControl from "../CustomFormControl";
import EditProfileSchema from "./EditProfileSchema";
import { updateProfile } from "../../actions/users";
import LoadingWrapper from "../LoadingWrapper";
import LoadingForm from "../LoadingForm";
import { hasServerError } from "../../helpers/serverErrorHelper";
import ErrorBox from "../ErrorBox";
import CustomFormLabel from "../CustomFormLabel";
import { isRequiredField } from "../../helpers/formHelper";
import OptionPlaceholder from "../OptionPlaceholder";
import FieldErrorBlock from "../FieldErrorBlock";
import { countryCodeMaxLength } from "../../helpers/formSchemaHelper";

const schema = EditProfileSchema;

const EditProfileFormControl = (props) => (
  <CustomFormControl {...props} validationSchema={schema} />
);

const EditProfileSelect = ({ children, ...props }) => {
  const { name } = props;
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <>
      <CustomFormLabel validationSchema={schema} fieldName={name}>
        {t(`editprofile_form_label_${name}`)}
      </CustomFormLabel>
      <Form.Control
        {...field}
        as="select"
        name={name}
        required={isRequiredField(schema, name)}
      >
        {children}
      </Form.Control>
      {touched && error && (
        <FieldErrorBlock>{t(error.key, error.values)}</FieldErrorBlock>
      )}
    </>
  );
};

const EditProfile = ({
  userId,
  loading,
  loadingError,
  initialValues,
  history,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [changingPassword, setChangingPassword] = useState(false);
  const { errorCode, success } = useSelector(
    (state) => state.forms.updateProfile
  );
  const twofactorAuthEnabled =
    initialValues && !!Number(initialValues["2fa_enabled"]);

  return (
    <div>
      <Title>{t("editprofile_form_title")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={!!loadingError}
        errorMsg={t("editprofile_form_error_loading")}
        loadingComponent={<LoadingForm />}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            currentpassword: "",
            firstname: "",
            surname: "",
            phone: "",
            email: "",
            company: "",
            organizationtype: "",
            address: "",
            countrycd: "",
            zip: "",
            city: "",
            newpassword: "",
            confirmpassword: "",
            ...initialValues,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            dispatch(updateProfile(userId, values));
          }}
        >
          {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
                {hasServerError(errorCode) && (
                  <ErrorBox msg={t(`editprofile_form_error_${errorCode}`)} />
                )}
                <Row>
                  <Col lg={4}>
                    <h2>{t("editprofile_form_heading_currentpassword")}</h2>
                    <p className={"section-description"}>
                      {t("editprofile_form_desc_currentpassword")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formCurrentPassword">
                        <EditProfileFormControl
                          name="currentpassword"
                          type="password"
                          label={t("editprofile_form_label_currentpassword")}
                        />
                      </Form.Group>
                    </Form.Row>
                    {!changingPassword && (
                      <Form.Row>
                        <Form.Group as={Col}>
                          {/* TODO: make this a blue link */}
                          <button
                            onClick={() => setChangingPassword(true)}
                            className="form-link"
                          >
                            {t("editprofile_form_label_changepassword")}
                          </button>
                        </Form.Group>
                      </Form.Row>
                    )}
                    {changingPassword && (
                      <>
                        <p>{t("editprofile_form_password_requirements")}</p>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formNewPassword">
                            <EditProfileFormControl
                              name="newpassword"
                              type="password"
                              label={t("editprofile_form_label_password")}
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formConfirmPassword">
                            <EditProfileFormControl
                              name="confirmpassword"
                              type="password"
                              label={t(
                                "editprofile_form_label_confirmpassword"
                              )}
                            />
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}
                  </Col>
                </Row>
                <HorizontalLine className={"double-vertical-margin"} />
                <Row>
                  <Col lg={4}>
                    <h2>{t("security")}</h2>
                    <p className={"section-description"}>
                      {t("security_intro")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <div>{t("2fa")}</div>
                    <p className={"section-description"}>{t("2fa_intro")}</p>
                    <div
                      className={`text-${
                        (twofactorAuthEnabled && "success") || "danger"
                      }`}
                    >
                      {twofactorAuthEnabled
                        ? t("2fa_enabled_intro")
                        : t("2fa_disabled_intro")}
                    </div>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <button
                          onClick={() =>
                            history.push("/user/2factor-authentication")
                          }
                          className="form-link"
                        >
                          {t("2fa_manage")}
                        </button>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Row>
                <HorizontalLine className={"double-vertical-margin"} />
                <Row>
                  <Col lg={4}>
                    <h2>{t("editprofile_form_heading_personal_info")}</h2>
                    <p className={"section-description"}>
                      {t("editprofile_form_desc_personal_info")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formFirstname">
                        <EditProfileFormControl
                          name={"firstname"}
                          label={t("editprofile_form_label_firstname")}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formSurname">
                        <EditProfileFormControl
                          name={"surname"}
                          label={t("editprofile_form_label_surname")}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="formPhone">
                      <EditProfileFormControl
                        type="tel"
                        name={"phone"}
                        label={t("editprofile_form_label_phone")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                      <EditProfileFormControl
                        type="email"
                        name={"email"}
                        label={t("editprofile_form_label_email")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <HorizontalLine className={"double-vertical-margin"} />
                <Row>
                  <Col lg={4}>
                    <h2>{t("editprofile_form_heading_organization_info")}</h2>
                    <p className={"section-description"}>
                      {t("editprofile_form_desc_organization_info")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formCompany">
                        <EditProfileFormControl
                          name={"company"}
                          label={t("editprofile_form_label_company")}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formOrganizationType">
                        <EditProfileSelect name="organizationtype">
                          <OptionPlaceholder>
                            {t(
                              "editprofile_form_label_organizationtype_choose"
                            )}
                          </OptionPlaceholder>
                          <option value="10">
                            {t(
                              "editprofile_form_label_organizationtype_academic"
                            )}
                          </option>
                          <option value="20">
                            {t(
                              "editprofile_form_label_organizationtype_industry"
                            )}
                          </option>
                          <option value="30">
                            {t("editprofile_form_label_organizationtype_other")}
                          </option>
                        </EditProfileSelect>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="formAddress">
                      <EditProfileFormControl
                        type="text"
                        name={"address"}
                        label={t("editprofile_form_label_address")}
                      />
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formCountry">
                        <EditProfileFormControl
                          name={"countrycd"}
                          maxLength={countryCodeMaxLength}
                          label={t("editprofile_form_label_countrycd", {
                            countryCodeMaxLength,
                          })}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formZip">
                        <EditProfileFormControl
                          name={"zip"}
                          label={t("editprofile_form_label_zip")}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCity">
                        <EditProfileFormControl
                          name={"city"}
                          label={t("editprofile_form_label_city")}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Row>
              </Container>

              <HorizontalLine className={"top-margin"} />
              {
                <Container>
                  <Row>
                    <Col lg={{ span: 8, offset: 4 }}>
                      <div className={"button-group"}>
                        {!success ? (
                          <button
                            disabled={isSubmitting}
                            className={"button"}
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                "submitForApprovalClicked",
                                true,
                                false
                              );
                              handleSubmit();
                            }}
                          >
                            {t("editprofile_form_submit")}
                          </button>
                        ) : (
                          <span>{t("editprofile_form_success_message")}</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              }
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </div>
  );
};

EditProfile.propTypes = {
  userId: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
};

export default EditProfile;
