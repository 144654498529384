import axios from "axios";
import {
  getToken,
  removeToken,
  redirectToLogin,
  redirectTo2fa,
} from "./session";
import i18next from "i18next";

axios.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token && token.length) {
      config.headers.Authorization = `Bearer ${token}`;
      if (i18next && i18next.language) {
        config.headers["Accept-Language"] = i18next.language;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      removeToken();
      redirectToLogin();
      return;
    }

    if (
      error.response.status === 403 &&
      error.response.data.message === "2FA_NOT_ENABLED"
    ) {
      redirectTo2fa();
      return;
    }

    return Promise.reject(error);
  }
);

export default axios;
