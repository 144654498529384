import React from "react";
import PropTypes from "prop-types";
import styles from "./UserHeader.module.scss";
import logo from "../../assets/img/logo_blue.svg";

const UserHeader = ({ title }) => {
  return (
    <header className={styles.userHeader}>
      <div className={styles.logo}>
        <img src={logo} alt="" />
      </div>
      <h1>{title}</h1>
    </header>
  );
};

UserHeader.propTypes = {
  title: PropTypes.string,
};

export default UserHeader;
