import Cookies from "js-cookie";
import config from "../config";
import { decode } from "jsonwebtoken";

export const ROLE_RESEARCHER = "RESEARCHER";
export const ROLE_BIOBANK_ADMIN = "BIOBANK_ADMIN";
export const ROLE_ADMIN = "ADMIN";

const getEnvironmentForCookie = () => {
  const env = config.appEnv;
  return env && env.length ? env : "";
};
const getCookieName = () => {
  const env = getEnvironmentForCookie();
  const prefix = env !== "production" ? `${env}_` : "";
  return `${prefix}access_token`;
};

const cookieName = getCookieName();

export const getToken = () => {
  return Cookies.get(cookieName);
};

export const setToken = (token) => {
  Cookies.set(cookieName, token, {
    domain: config.cookieDomain,
    path: config.cookiePath,
    secure: parseInt(config.secureCookies) === 1,
  });
};

export const verifyAccessToken = (token) => {
  try {
    const tokenData = decode(token);
    if (
      tokenData.userId &&
      tokenData.userId > 0 &&
      tokenData.bb_id &&
      parseInt(tokenData.bb_id) === 70 &&
      tokenData.newAPI === true
    ) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const isAuthenticated = () => {
  if (parseInt(config.enableJwtAuth) === 1) {
    const token = getToken();
    return token && token.length && verifyAccessToken(token);
  }
  return true;
};

export const isResearcher = (roles) => {
  return roles && roles.includes(ROLE_RESEARCHER);
};

export const isAdmin = (roles) => {
  return roles && roles.includes(ROLE_ADMIN);
};

export const isBiobankAdmin = (roles) => {
  return roles && roles.includes(ROLE_BIOBANK_ADMIN);
};

export const removeToken = () => {
  Cookies.remove(cookieName, {
    domain: config.cookieDomain,
    path: config.cookiePath,
    secure: parseInt(config.secureCookies) === 1,
  });
};

export const redirectToLogin = () => {
  const loginUrl = `${config.loginUrl}`;
  window.location.href = loginUrl;
};

export const redirectTo2fa = () => {
  window.location.href = "/user/2factor-authentication?error=2fa";
};
