import * as Yup from "yup";
import {
  address,
  commonRequiredContactInfoFields,
  email,
  langId,
  organizationType,
  password,
} from "../../helpers/formSchemaHelper";

const EditProfileSchema = Yup.object().shape({
  currentpassword: password.required(),
  email: email,
  newpassword: password,
  confirmpassword: password.when("newpassword", {
    is: (val) => val && val.length > 0,
    then: Yup.string()
      .oneOf([Yup.ref("newpassword")], {
        key: "forms.validation.password.must_match",
      })
      .required(),
  }),
  ...commonRequiredContactInfoFields,
  organizationtype: organizationType,
  address: address,
  langid: langId,
});

export default EditProfileSchema;
