export const transformData = (arrayFields, data, delimiter = " ") => {
  const transformedData = { ...data };
  arrayFields.forEach((field) => {
    if (!data[field]) {
      transformedData[field] = [];
    } else {
      transformedData[field] = data[field].split(delimiter);
    }
  });
  return transformedData;
};

export const transformNulls = (fields, data) => {
  const transformedData = { ...data };
  fields.forEach((field) => {
    if (data[field] === null) {
      transformedData[field] = "";
    } else {
      transformedData[field] = data[field];
    }
  });
  return transformedData;
};

export const transformBooleans = (fields, data) => {
  const transformedData = { ...data };
  fields.forEach((field) => {
    if (data[field] === "1") {
      transformedData[field] = true;
    } else {
      transformedData[field] = false;
    }
  });
  return transformedData;
};
