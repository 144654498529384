import * as Yup from "yup";
import util from "util";
import axios from "./axios";
import config from "../config";

const projectNumberCheckUrl = `${config.fingeniousApiUrl}/project/number/%s`;

export const requiredText = Yup.string().min(2).required();

export const requiredHTML = Yup.string()
  .trim()
  .test("has-html-content", "Required value", (value) => {
    const condition = value && value.length && value !== "<p></p>";
    return condition;
  })
  .required();

export const requiredArray = Yup.array().required().min(1);

export const getContactInfoFields = (contactInfoFields, prefix) =>
  Object.entries(contactInfoFields).reduce((result, [key, value]) => {
    result[`${prefix}${key}`] = value;
    return result;
  }, {});

export const requiredProjectNumber = Yup.string()
  .required()
  .test(
    "projectNumber",
    { key: "invalid_project_number" }, // expect an i18n message to be passed in
    async (value) => {
      try {
        const response = await axios.get(
          util.format(projectNumberCheckUrl, value)
        );

        if (response.status === 200) {
          return true;
        }

        return false;
      } catch (e) {
        return false;
      }
    }
  );
