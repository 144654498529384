import React from "react";
import { isIE } from "react-device-detect";
import IEFallback from "./IEFallback";

const withIEFallback = (WrappedComponent) => {
  return (props) => {
    if (isIE) return <IEFallback />;

    return <WrappedComponent {...props} />;
  };
};

export default withIEFallback;
