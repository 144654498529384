import React from "react";
import { useParams } from "react-router-dom";

import MainContainer from "../components/MainContainer";
import ChangePassword from "../components/PasswordReset/ChangePassword";
import UserHeader from "../components/User/UserHeader";
import { useTranslation } from "react-i18next";

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  return (
    <div className="App page-user">
      <MainContainer>
        <div className="finbb-container">
          <UserHeader title={t("change_password_reset_form_title")} />
          <ChangePassword token={token} />
        </div>
      </MainContainer>
    </div>
  );
};

export default ChangePasswordPage;
