import { useSelector } from "react-redux";

const useUserRoles = () => {
  const userData = useSelector((state) => state.user.data);

  const hasRole = (role) => {
    if (!userData || !userData.roles) {
      return false;
    }

    return userData.roles.includes(role);
  };

  const isAdmin = () => hasRole("ADMIN");
  const isBiobankAdmin = () => hasRole("BIOBANK_ADMIN");
  const isResearcher = () => hasRole("RESEARCHER");

  return {
    roles: userData.roles,
    hasRole,
    isAdmin,
    isBiobankAdmin,
    isResearcher,
  };
};

export default useUserRoles;
