import React from "react";
import { Formik, useField } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import CustomFormControl from "../CustomFormControl";
import { useTranslation } from "react-i18next";
import RegisterSchema from "./RegisterSchema";

import { register } from "../../actions/users";

import styles from "./Register.module.scss";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import { isRequiredField } from "../../helpers/formHelper";
import CustomFormLabel from "../CustomFormLabel";
import { hasServerError } from "../../helpers/serverErrorHelper";
import OptionPlaceholder from "../OptionPlaceholder";
import FieldErrorBlock from "../FieldErrorBlock";
import { countryCodeMaxLength } from "../../helpers/formSchemaHelper";

const schema = RegisterSchema;

const RegisterFormControl = (props) => {
  return <CustomFormControl {...props} validationSchema={schema} />;
};

const RegisterDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <p
      className="form-text"
      dangerouslySetInnerHTML={{ __html: t("register_form_disclaimer") }}
    />
  );
};

const RegisterTextField = ({ name, type = "text", ...props }) => {
  const { t } = useTranslation();
  return (
    <Form.Row>
      <Form.Group as={Col} controlId={`form${name}`}>
        <RegisterFormControl
          {...props}
          name={name}
          label={t(`register_form_label_${name}`)}
          type={type}
          required={isRequiredField(schema, name)}
        />
      </Form.Group>
    </Form.Row>
  );
};

const RegisterHorizontalLine = () => {
  return (
    <Col lg={12}>
      <HorizontalLine />
    </Col>
  );
};

const RegisterExtraLinks = () => {
  const { t } = useTranslation();

  return (
    <div className="form-extra">
      <p
        dangerouslySetInnerHTML={{
          __html: t("register_form_login_link", {
            url: "/user/login",
          }),
        }}
      />
    </div>
  );
};

const RegisterSelect = ({ children, ...props }) => {
  const { name } = props;
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  return (
    <Form.Row>
      <Form.Group as={Col} controlId={`form${name}`}>
        <CustomFormLabel validationSchema={schema} fieldName={name}>
          {t(`register_form_label_${name}`)}
        </CustomFormLabel>
        <Form.Control
          {...field}
          as="select"
          name={name}
          required={isRequiredField(schema, name)}
        >
          {children}
        </Form.Control>
        {touched && error && (
          <FieldErrorBlock>{t(error.key, error.values)}</FieldErrorBlock>
        )}
      </Form.Group>
    </Form.Row>
  );
};

const RegisterSubmit = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <button disabled={disabled} className="button button-block" type="submit">
      {t("register_form_label_submit")}
    </button>
  );
};

const registerFormDefaultState = {
  email: "",
  password: "",
  confirmpassword: "",
  firstname: "",
  surname: "",
  organizationtype: "",
  company: "",
  phone: "",
  address: "",
  countrycd: "FI",
  zip: "",
  city: "",
};

const Register = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.lng);
  const errorCode = useSelector((state) => state.forms.register.errorCode);
  const { t } = useTranslation();

  return (
    <div className={styles.registerForm}>
      <Formik
        initialValues={{ ...registerFormDefaultState, langid: lng }}
        validationSchema={schema}
        onSubmit={(values) => {
          dispatch(register(values));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {hasServerError(errorCode) && (
              <Alert variant="danger">
                {t(`register_form_error_${errorCode}`)}
              </Alert>
            )}
            <p className={styles.registerIntro}>{t("register_form_intro")}</p>
            <Row>
              <Col lg={12}>
                <RegisterTextField name="email" type="email" />
              </Col>
              <Col lg={12}>
                <RegisterTextField name="password" type="password" />
              </Col>
              <Col lg={12}>
                <RegisterTextField name="confirmpassword" type="password" />
              </Col>
              <Col lg={12}>
                <RegisterSelect name="langid">
                  <option value="fi">FI</option>
                  <option value="en">EN</option>
                </RegisterSelect>
              </Col>
              <RegisterHorizontalLine />
              <Col sm={6}>
                <RegisterTextField name="firstname" />
              </Col>
              <Col sm={6}>
                <RegisterTextField name="surname" />
              </Col>
              <Col lg={12}>
                <RegisterTextField name="phone" />
              </Col>
              <RegisterHorizontalLine />
              <Col lg={12}>
                <RegisterSelect name="organizationtype">
                  <OptionPlaceholder>
                    {t("register_form_label_organizationtype_choose")}
                  </OptionPlaceholder>
                  <option value="10">
                    {t("register_form_label_organizationtype_academic")}
                  </option>
                  <option value="20">
                    {t("register_form_label_organizationtype_industry")}
                  </option>
                  <option value="30">
                    {t("register_form_label_organizationtype_other")}
                  </option>
                </RegisterSelect>
              </Col>
              <Col lg={12}>
                <RegisterTextField name="company" />
              </Col>
              <Col lg={12}>
                <RegisterTextField name="address" />
              </Col>
              <Col sm={4}>
                <RegisterTextField
                  name="countrycd"
                  maxLength={countryCodeMaxLength}
                />
              </Col>
              <Col sm={4}>
                <RegisterTextField name="zip" />
              </Col>
              <Col sm={4}>
                <RegisterTextField name="city" />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <RegisterDisclaimer />
                <RegisterSubmit disabled={isSubmitting} />
                <RegisterExtraLinks />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
