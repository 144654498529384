const createDownload = (csv, filename = "data") => {
  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  const csvURL = window.URL.createObjectURL(csvData);

  const testLink = document.createElement("a");
  testLink.href = csvURL;
  testLink.setAttribute("download", filename + ".csv");
  testLink.click();
};

export default createDownload;
