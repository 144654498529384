import React from "react";
import { Link } from "react-router-dom";

import useUserRoles from "../../hooks/useUserRoles";
import useFetchSurvey from "../../hooks/useFetchSurvey";
import { useGetContactInfo } from "../../actions/contact";
import { useTranslation } from "react-i18next";
import SurveyEditForm from "../../components/SurveyEdit/SurveyEditForm";
import SurveyView from "../../components/SurveyView/SurveyView";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import LoadingForm from "../../components/LoadingForm";
import LoadingWrapper from "../../components/LoadingWrapper";

import {
  getSurveyAddUserUrl,
  getSurveyUploadSubjectsUrl,
} from "../../helpers/linkGenerator";

const SurveyEditPageUserActions = ({ surveyId, surveyType }) => {
  const { t } = useTranslation();
  const { isAdmin, isBiobankAdmin } = useUserRoles();
  const contactsDownloader = useGetContactInfo(surveyId);

  return [
    isAdmin() && (
      <Link to={getSurveyAddUserUrl(surveyId)}>
        <button type="button" className={"button"}>
          + {t("survey_add_user")}
        </button>
      </Link>
    ),
    isBiobankAdmin() && (
      <Link to={getSurveyUploadSubjectsUrl(surveyId)}>
        <button type="button" className={"button"}>
          + {t("survey_upload_participants")}
        </button>
      </Link>
    ),
    isBiobankAdmin() && surveyType === "readonly" && (
      <button className={"button"} onClick={() => contactsDownloader()}>
        {t("survey_download_contact_info")}
      </button>
    ),
  ].filter(Boolean);
};

const SurveyEditPage = ({ match }) => {
  const surveyId = parseInt(match.params.surveyId);

  const { t } = useTranslation();
  const { data, loading, error } = useFetchSurvey(surveyId);
  const { isAdmin, isResearcher } = useUserRoles();

  let userActions = <></>;
  let content = <></>;
  let pageTitle = "";
  if (data) {
    const published = parseInt(data.published);
    userActions = (
      <SurveyEditPageUserActions surveyId={surveyId} surveyType={data.type} />
    );
    content = <SurveyView surveyId={surveyId} initialValues={data} />;
    pageTitle = t("survey_view_title");
    if (
      isAdmin() ||
      (isResearcher() && (!published || data.type === "readonly"))
    ) {
      content = <SurveyEditForm surveyId={surveyId} initialValues={data} />;
      pageTitle = t("survey_edit");
    }
  }

  return (
    <div className="App page-user-surveylist">
      <Title rightChildren={userActions}>{pageTitle}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <LoadingWrapper
        isLoading={loading}
        isInErrorState={!!error}
        errorMsg={t("surveyedit_form_error_loading")}
        loadingComponent={<LoadingForm />}
      >
        {content}
      </LoadingWrapper>
    </div>
  );
};

export default SurveyEditPage;
