import {
  USER_LOGIN_SUCCESSFUL,
  USER_LOGIN_FAILURE,
  USER_SAVE_2FA_DATA,
  USER_RESET_2FA_DATA,
  USER_CHECK_2FA_ENABLED,
} from "../actions/users.actions";

const initialState = {
  token: null,
  data: {},
  secret: null,
  qrCode: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESSFUL:
      return {
        token: action.token,
        data: action.data,
      };
    case USER_LOGIN_FAILURE:
      return initialState;
    case USER_SAVE_2FA_DATA:
      return {
        ...state,
        secret: action.secret,
        qrCode: action.qrCode,
      };
    case USER_RESET_2FA_DATA:
      return {
        ...state,
        secret: null,
        qrCode: null,
      };
    case USER_CHECK_2FA_ENABLED:
      return {
        ...state,
        data: {
          ...state.data,
          twoFactorEnabled: action.status,
        },
      };
    default:
      return state;
  }
};
