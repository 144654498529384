import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useGetConsents } from "../../actions/consents";
import { useHas2FAEnabled } from "../../actions/users";
import { redirectTo2fa } from "../../helpers/session";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Title from "../Title/Title";
import HorizontalLine from "../HorizontalLine/HorizontalLine";

import styles from "./ConsentDownload.module.scss";

export default function ConsentDownload() {
  const { t } = useTranslation();
  const getConsents = useGetConsents();
  const check2FAStatus = useHas2FAEnabled();
  const twoFAEnabled = useSelector((state) => {
    if (!state.user.data) {
      return false;
    }
    return state.user.data.twoFactorEnabled;
  });
  const userId = useSelector((state) => {
    if (!state.user.data) {
      return null;
    }
    return state.user.data.userId;
  });

  useEffect(() => {
    if (userId) {
      check2FAStatus(userId);
    }
  }, [userId, check2FAStatus]);

  if (twoFAEnabled === false) {
    redirectTo2fa();
  }

  const handleConsentDownload = () => {
    getConsents();
  };

  return (
    <div>
      <Title>{t("consent_download_title")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <Container>
        <Row>
          <Col lg={12}>
            <div className={styles.infoText}>{t("consent_download_info")}</div>
            <div className={styles.buttonContainer}>
              <button
                className={"button"}
                type="button"
                onClick={handleConsentDownload}
              >
                {t("consent_download_button")}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
