import iconFile from "./icon_file.svg";
import * as React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formatFileSize } from "../../helpers/fileSizeFormatter";
import styles from "./file-uploader.module.scss";

const RequestFileList = ({ files, handleRemoveFile, disabled }) => {
  const { t } = useTranslation();

  return files.map(({ size, name, description, comment, url }) => (
    <div className={styles.fileBlockContainer} key={name}>
      <div className={styles.fileBlock}>
        <img src={iconFile} alt={name} className={styles.fileIcon} />
        <div>
          <a
            className={styles.fileName}
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>{" "}
          <span className={styles.description}>{description}</span>
          <div className={styles.fileSize}>
            {t("file_size")}: {formatFileSize(size)}
          </div>
          <div className={styles.description}>{comment}</div>
        </div>
      </div>
      {!disabled && (
        <button
          className={"button left-margin"}
          type={"button"}
          onClick={() => handleRemoveFile(name)}
        >
          {t("delete_file")}
        </button>
      )}
    </div>
  ));
};

RequestFileList.propTypes = {
  files: PropTypes.array.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default RequestFileList;
