import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import CustomFormControl from "../CustomFormControl";
import { useTranslation } from "react-i18next";
import LoginSchema from "./LoginSchema";
import { isAuthenticated } from "../../helpers/session";
import { Redirect } from "react-router-dom";
import { getSurveyListUrl } from "../../helpers/linkGenerator";
import { login } from "../../actions/users";
import { isRequiredField } from "../../helpers/formHelper";
import { hasServerError } from "../../helpers/serverErrorHelper";
import { privacyPolicyUrlPreLogin } from "../../pages/static/PrivacyPolicy";
import { termsAndConditionsUrlPreLogin } from "../../pages/static/TermsAndConditions";

const schema = LoginSchema;

const LoginFormControl = (props) => (
  <CustomFormControl
    {...props}
    validationSchema={schema}
    enableValidation={false}
  />
);

const LoginDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <p
      className="form-text"
      dangerouslySetInnerHTML={{
        __html: t("login_form_disclaimer", {
          termsUrl: termsAndConditionsUrlPreLogin,
          privacyUrl: privacyPolicyUrlPreLogin,
        }),
      }}
    />
  );
};

const LoginExtraLinks = () => {
  const { t } = useTranslation();

  return (
    <div className="form-extra">
      <p
        dangerouslySetInnerHTML={{
          __html: t("login_form_register_link", {
            url: "/user/register",
          }),
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: t("login_form_forgot_link", {
            url: "/user/request-password-reset",
          }),
        }}
      />
    </div>
  );
};

const LoginFieldEmail = () => {
  const { t } = useTranslation();
  return (
    <Form.Group as={Col} controlId="formEmail">
      <LoginFormControl
        name="email"
        label={t("login_form_label_email")}
        type="email"
        required={isRequiredField(schema, "email")}
      />
    </Form.Group>
  );
};

const LoginFieldVerificationCode = () => {
  const { t } = useTranslation();
  return (
    <Form.Group as={Col} controlId="formVerification">
      <LoginFormControl
        name="verification"
        label={t("login_form_label_verification")}
        type="text"
        required={isRequiredField(schema, "verification")}
      />
    </Form.Group>
  );
};

const LoginFieldPassword = () => {
  const { t } = useTranslation();
  return (
    <Form.Group as={Col} controlId="formPassword">
      <LoginFormControl
        type="password"
        label={t("login_form_label_password")}
        name={"password"}
        required={isRequiredField(schema, "password")}
        minLength={6}
      />
    </Form.Group>
  );
};

const LoginSubmit = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <button disabled={disabled} className="button button-block" type="submit">
      {t("login_form_label_submit")}
    </button>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [twoFactorNeeded, setTwoFactorNeeded] = useState(false);
  const formState = useSelector((state) => state.forms.login);
  const errorCode = formState.errorCode;
  const { t } = useTranslation();

  useEffect(() => {
    if (formState.twoFactorNeeded) {
      setTwoFactorNeeded(true);
    }
  }, [formState]);

  if (isAuthenticated()) {
    return <Redirect to={getSurveyListUrl()} />;
  }

  const BasicLoginForm = () => (
    <Row>
      <Col lg={12}>
        <Form.Row>
          <LoginFieldEmail />
        </Form.Row>
      </Col>
      <Col lg={12}>
        <Form.Row>
          <LoginFieldPassword />
        </Form.Row>
      </Col>
    </Row>
  );

  const TwoFactorLoginForm = () => (
    <Row>
      <Col lg={12}>
        <Form.Row>
          <LoginFieldVerificationCode />
        </Form.Row>
      </Col>
    </Row>
  );

  return (
    <div>
      <Formik
        initialValues={{
          email: credentials.email,
          password: credentials.password,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          setCredentials({
            email: values.email,
            password: values.password,
            verification: "",
          });
          dispatch(login(values.email, values.password, values.verification));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {hasServerError(errorCode) &&
            formState.verificationValid !== null ? (
              <Alert variant="danger">
                {t(`login_form_error_${errorCode}`)}
              </Alert>
            ) : null}
            {twoFactorNeeded ? <TwoFactorLoginForm /> : <BasicLoginForm />}
            <Row>
              <Col lg={12}>
                <LoginDisclaimer />
                <LoginSubmit />
                <LoginExtraLinks />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
