import InfoBlock from "../InfoBlock/InfoBlock";
import React from "react";
import { useTranslation } from "react-i18next";
import survey from "../../assets/img/survey.svg";

const EmptySurveyListPlaceholder = ({ title, type }) => {
  const { t } = useTranslation();
  return (
    <InfoBlock
      title={t(`no_${type}_surveys_placeholder`)}
      iconSrc={survey}
      description={""}
      button={null}
    />
  );
};

export default EmptySurveyListPlaceholder;
