import { useState } from "react";
import Papa from "papaparse";

import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";
import { validateImportSubjectCSVRow } from "../helpers/survey";

const surveyApiUrl = `${config.fingeniousApiUrl}/survey`;

// const createDownload = csv => {
//   const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

//   const csvURL = window.URL.createObjectURL(csvData);

//   const testLink = document.createElement("a");
//   testLink.href = csvURL;
//   testLink.setAttribute("test", "test.csv");
//   testLink.click();
// };

const isValid = (rows) => {
  return !rows.some((row) => {
    const validationResult = validateImportSubjectCSVRow(row);
    if (!validationResult) {
      return true;
    }
    return false;
  });
};

const createJob = async (surveyId) => {
  try {
    const response = await axios.post(
      `${surveyApiUrl}/${surveyId}/createImportJob`,
      null,
      {
        credentials: "include",
      }
    );
    if (response.status === 200) {
      return response.data.data.jobId;
    }
  } catch (error) {
    logError(error);
    throw new Error(error);
  }
};

const uploadCSVFile = async (jobId, surveyId, data) => {
  const blob = new Blob([data]);
  try {
    const formData = new FormData();
    formData.append("jobId", jobId);
    formData.append("csv", blob, "test.csv");

    const response = await axios.post(
      `${surveyApiUrl}/${surveyId}/subjects`,
      formData,
      {
        credentials: "include",
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    logError(error);
    throw new Error(error);
  }
};

const basicParse = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      worker: false,
      skipEmptyLines: true,
      chunkSize: 10000, // 100000
      complete: function (results) {
        if (!results) {
          return reject();
        }
        resolve(results);
      },
    });
  });
};

const useImportSubjects = (surveyId, completeDelayNotification = 0) => {
  const [started, setStarted] = useState(false);
  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [complete, setComplete] = useState(false);
  const [jobId, setJobId] = useState(null);

  const doImport = async (file) => {
    setStarted(true);

    let jobId = null;
    try {
      jobId = await createJob(surveyId);
      setJobId(jobId);
    } catch (e) {
      setError(e);
      return;
    }

    const results = await basicParse(file);

    const total = results.data.length;
    setTotal(total);

    const valid = isValid(results.data);

    if (valid) {
      const promises = [];

      // reparse and split into chunks so the server can handle the load
      Papa.parse(file, {
        worker: false,
        skipEmptyLines: true,
        chunkSize: 10000, // 100000
        chunk: async function (results) {
          promises.push(
            new Promise(async (resolve, reject) => {
              const csv = Papa.unparse(results.data);

              try {
                const response = await uploadCSVFile(jobId, surveyId, csv);
                resolve(response);

                // need to call this with functional syntax to actually get it to increment
                // due to being in a loop
                setProgress((progress) => progress + response.totalImported);
              } catch (e) {
                return reject(e);
              }
            })
          );
        },
        complete: (results) => {
          Promise.all(promises)
            .then((responses) => {
              setTimeout(() => {
                setComplete(true);
              }, completeDelayNotification);
            })
            .catch((error) => {
              setError(error);
            });
        },
      });
    }
  };

  return {
    doImport,
    started,
    jobId,
    progress,
    total,
    complete,
    error,
  };
};

export default useImportSubjects;
