import React from "react";
import { useState } from "react";
import AutoSuggest from "@deanc/react-autosuggest";
import axios from "../../helpers/axios";
import { logError } from "../../helpers/logger";
import config from "../../config";

const userApiUrl = `${config.fingeniousApiUrl}/user`;

const fetchUsers = async (val) => {
  const formData = new FormData();
  formData.append("email", val);

  try {
    const response = await axios.post(`${userApiUrl}/search`, formData, {
      credentials: "include",
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (e) {
    logError(e);
    return [];
  }
};

const SurveyFindUser = ({ setSelectedValue }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  return (
    <div className="survey-find-user">
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={async ({ value }) => {
          setValue(value);
          setSelectedValue(null);
          if (value.length >= 3) {
            const suggestions = await fetchUsers(value);
            setSuggestions(suggestions);
          }
        }}
        onSuggestionSelected={(_, { suggestion }) => {
          setSelectedValue(suggestion);
        }}
        getSuggestionValue={(suggestion) => suggestion.email}
        renderSuggestion={(suggestion) => (
          <span className="link">
            {suggestion.email} ({suggestion.firstname} {suggestion.surname})
          </span>
        )}
        inputProps={{
          placeholder: "john.smith@biobank.com",
          className: "form-control",
          value: value,
          onChange: (_, { newValue, method }) => {
            setValue(newValue);
          },
        }}
        highlightFirstSuggestion={true}
      />
    </div>
  );
};

export default SurveyFindUser;
