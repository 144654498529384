import React from "react";
import { string, func } from "prop-types";

const getLanguage = (currentLanguage) => {
  switch (currentLanguage) {
    case "en":
      return "Suomeksi";
    default:
      return "In English";
  }
};

const LanguageChangeControl = ({ currentLanguage, onClick }) => (
  <button className="nav-link language-change-control" onClick={onClick}>
    {getLanguage(currentLanguage)}
  </button>
);

LanguageChangeControl.propTypes = {
  currentLanguage: string.isRequired,
  onClick: func.isRequired,
};

export default LanguageChangeControl;
