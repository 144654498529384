import Alert from "react-bootstrap/Alert";
import React from "react";
import { node } from "prop-types";

const ErrorBox = ({ msg }) => <Alert variant="danger">{msg}</Alert>;

ErrorBox.propTypes = {
  msg: node.isRequired,
};

export default ErrorBox;
