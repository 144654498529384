import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./NavigationUser.module.scss";
import { NavLink } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const NavigationUserDropdown = ({ active, toggle }) => {
  const { t } = useTranslation();

  const dropdownClasses = classNames(styles.navigationUserDropdown, {
    [styles.navigationUserDropdownVisible]: active,
  });

  return (
    <ul className={dropdownClasses}>
      <li>
        <NavLink to="/user/edit-profile" onClick={() => toggle(false)}>
          {t("navigation_dropdown_edit_profile")}
        </NavLink>
      </li>
      <li>
        <NavLink to="/user/logout" onClick={() => toggle(false)}>
          {t("navigation_dropdown_logout")}
        </NavLink>
      </li>
    </ul>
  );
};

const NavigationUser = () => {
  const name = useSelector(
    (state) => state.user.data.firstname + " " + state.user.data.surname
  );

  const roles = useSelector((state) => state.user.data.roles);
  const roleDisplay = roles && roles.length ? roles.join(",") : null;

  // handle clicking outside menu collapse stuff
  const divRef = useRef();
  const buttonRef = useRef();
  const [menuActive, setMenuActive] = useState(false);
  useOnClickOutside(divRef, (e) => {
    if (!buttonRef.current.contains(e.target)) {
      setMenuActive(false);
    }
  });

  return (
    <div className={`${styles.navigationUserContainer} nav-link nav-user`}>
      <div
        className={`${styles.navigationUser} nav-user-inner`}
        ref={buttonRef}
        onClick={() => setMenuActive(!menuActive)}
      >
        {name} <span className="role">{roleDisplay}</span>
      </div>
      <div ref={divRef}>
        <NavigationUserDropdown active={menuActive} toggle={setMenuActive} />
      </div>
    </div>
  );
};

export default NavigationUser;
