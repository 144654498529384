import React from "react";

import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import SurveyUploadResults from "../../components/SurveyUploadResults/SurveyUploadResults";

const SurveyUploadResultsPage = ({ match }) => {
  const surveyId = parseInt(match.params.surveyId);
  return (
    <div className="App page-user-surveylist">
      <Title>TRANSLATION UPLOAD RESULTS</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <SurveyUploadResults surveyId={surveyId} />
    </div>
  );
};

export default SurveyUploadResultsPage;
