import axios from "../helpers/axios";
import { push } from "connected-react-router";
import { setToken } from "../helpers/session";
import jwt from "jsonwebtoken";
import config from "../config";
import notify from "../helpers/notification";

import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  USER_LOGIN,
  USER_LOGIN_SUCCESSFUL,
  USER_LOGIN_FAILURE,
  USER_LOGIN_FAILURE_2FA,
  USER_REGISTER,
  USER_REGISTER_SUCCESSFUL,
  USER_REGISTER_FAILURE,
  USER_REQUEST_PASSWORD_RESET,
  USER_REQUEST_PASSWORD_RESET_SUCCESSFUL,
  USER_REQUEST_PASSWORD_RESET_FAILURE,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESSFUL,
  USER_CHANGE_PASSWORD_FAILURE,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
  USER_UPDATE_PROFILE_FAILURE,
  USER_ENABLE_2FA,
  USER_RESET_FORM_STATE,
  USER_SAVE_2FA_DATA,
  USER_VERIFY_2FA,
  USER_VERIFY_2FA_SUCCESSFUL,
  USER_VERIFY_2FA_FAILURE,
  USER_ENABLE_2FA_SUCCESSFUL,
  USER_ENABLE_2FA_FAILURE,
  USER_DISABLE_2FA,
  USER_DISABLE_2FA_SUCCESSFUL,
  USER_DISABLE_2FA_FAILURE,
  USER_CHECK_2FA_ENABLED,
  USER_CHECK_2FA_FAILURE,
} from "./users.actions";
import i18next from "i18next";
import { logError } from "../helpers/logger";
import { getSurveyListUrl } from "../helpers/linkGenerator";

const userApiUrl = `${config.fingeniousApiUrl}/user`;

export const resetFormState = () => ({
  type: USER_RESET_FORM_STATE,
});

export const userLogin = () => ({
  type: USER_LOGIN,
});

export const userLoginSuccessful = (token, data) => ({
  type: USER_LOGIN_SUCCESSFUL,
  token,
  data,
});

export const userLoginFailure = (code, message) => ({
  type: USER_LOGIN_FAILURE,
  code,
  message,
});

export const userLoginFailure2fa = (
  code,
  message,
  verificationValid = null
) => ({
  type: USER_LOGIN_FAILURE_2FA,
  code,
  message,
  twoFactorNeeded: true,
  verificationValid,
});

export const login = (email, password, verificationCode = "") => {
  return async (dispatch) => {
    dispatch(userLogin());

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      if (verificationCode.length) {
        formData.append("verification", verificationCode);
      }

      const response = await axios.post(userApiUrl + "/login", formData, {
        credentials: "include",
      });

      if (response.status === 200) {
        const token = response.data.access_token;
        let tokenData = null;
        try {
          tokenData = jwt.decode(token);
        } catch (e) {
          logError(e);
          dispatch(userLoginFailure(403, null));
          return;
        }
        dispatch(processSuccessfulLogin(token, tokenData));
      } else {
        dispatch(userLoginFailure(response.status, response.data.message));
      }
    } catch (error) {
      logError(error);
      if (error.response) {
        const data = error.response.data;

        if (data["2fa"]) {
          if (data["2fa"]["code_provided"] && !data["2fa"]["code_valid"]) {
            dispatch(
              userLoginFailure2fa(error.response.status, "Needs 2fa", false)
            );
          } else {
            dispatch(userLoginFailure2fa(error.response.status, "Needs 2fa"));
          }
        } else {
          dispatch(
            userLoginFailure(error.response.status, error.response.data.message)
          );
        }
      } else {
        dispatch(userLoginFailure(500, i18next.t("form_api_offline")));
      }
    }
  };
};

export const processSuccessfulLogin = (token, data) => {
  return async (dispatch) => {
    setToken(token);
    dispatch(userLoginSuccessful(token, data));
    dispatch(push(getSurveyListUrl()));
    dispatch(resetFormState());
  };
};

export const userRegister = () => ({
  type: USER_REGISTER,
});

export const userRegistrationSuccessful = (token, data) => ({
  type: USER_REGISTER_SUCCESSFUL,
  token,
  data,
});

export const userRegistrationFailure = (code, message) => ({
  type: USER_REGISTER_FAILURE,
  code,
  message,
});

export const register = (fields) => {
  return async (dispatch) => {
    dispatch(userRegister());

    try {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(userApiUrl, formData, {
        credentials: "include",
      });
      if (response.status === 200) {
        const token = response.data.access_token;
        let tokenData = null;
        try {
          tokenData = jwt.decode(token);
        } catch (e) {
          logError(e);
          dispatch(userRegistrationFailure(403, null));
          return;
        }

        dispatch(
          processSuccessfulRegistration(
            fields["email"],
            fields["password"],
            token,
            tokenData
          )
        );
      } else {
        dispatch(
          userRegistrationFailure(response.status, response.data.message)
        );
      }
    } catch (error) {
      logError(error);
      if (error.response) {
        dispatch(
          userRegistrationFailure(
            error.response.status,
            error.response.data.message
          )
        );
      } else {
        dispatch(userRegistrationFailure(500, i18next.t("form_api_offline")));
      }
    }
  };
};

export const processSuccessfulRegistration = (email, password, token, data) => {
  return async (dispatch) => {
    dispatch(userRegistrationSuccessful());
    dispatch(login(email, password));
    dispatch(resetFormState());
  };
};

export const userRequestPasswordReset = () => ({
  type: USER_REQUEST_PASSWORD_RESET,
});

export const userRequestPasswordResetSuccessful = () => ({
  type: USER_REQUEST_PASSWORD_RESET_SUCCESSFUL,
});

export const userRequestPasswordResetFailure = (code, message) => ({
  type: USER_REQUEST_PASSWORD_RESET_FAILURE,
  code,
  message,
});

export const requestPasswordReset = (email) => {
  return async (dispatch) => {
    dispatch(userRequestPasswordReset());

    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await axios.post(
        userApiUrl + "/request-password-reset",
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        dispatch(userRequestPasswordResetSuccessful());
        setTimeout(() => {
          dispatch(resetFormState());
        }, 7500);
      }
    } catch (error) {
      logError(error);
      dispatch(
        userRequestPasswordResetFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};

export const userChangePassword = () => ({
  type: USER_CHANGE_PASSWORD,
});

export const userChangePasswordSuccessful = () => ({
  type: USER_CHANGE_PASSWORD_SUCCESSFUL,
});

export const userChangePasswordFailure = (code, message) => ({
  type: USER_CHANGE_PASSWORD_FAILURE,
  code,
  message,
});

export const changePassword = (token, email) => {
  return async (dispatch) => {
    dispatch(userChangePassword());

    try {
      const formData = new FormData();
      formData.append("password", email);
      formData.append("psw_reset_str", token);

      const response = await axios.post(
        userApiUrl + "/reset-password",
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        dispatch(userChangePasswordSuccessful());
        dispatch(resetFormState());
        notify(
          "change_password_form_label_submit",
          "change_password_form_done_message"
        );
        dispatch(push("/user/login"));
      }
    } catch (error) {
      logError(error);
      dispatch(
        userChangePasswordFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};

export const userEnable2FA = () => ({
  type: USER_ENABLE_2FA,
});

export const userEnable2FASuccessful = () => ({
  type: USER_ENABLE_2FA_SUCCESSFUL,
});

export const userEnable2FAFailure = (code, message) => ({
  type: USER_ENABLE_2FA_FAILURE,
  code,
  message,
});

export const userSave2FAData = (secret, qrCode) => ({
  type: USER_SAVE_2FA_DATA,
  secret,
  qrCode,
});

export const userReset2FAData = () => ({
  type: USER_SAVE_2FA_DATA,
});

export const userVerify2FA = () => ({
  type: USER_VERIFY_2FA,
});

export const userVerify2FASuccessful = () => ({
  type: USER_VERIFY_2FA_SUCCESSFUL,
});

export const userVerify2FAFailure = (code, message) => ({
  type: USER_VERIFY_2FA_FAILURE,
  code,
  message,
});

export const userDisable2FA = () => ({
  type: USER_DISABLE_2FA,
});

export const userDisable2FASuccessful = () => ({
  type: USER_DISABLE_2FA_SUCCESSFUL,
});

export const userDisable2FAFailure = (code, message) => ({
  type: USER_DISABLE_2FA_FAILURE,
  code,
  message,
});

export const disable2FA = (userId, email, password) => {
  return async (dispatch) => {
    dispatch(userVerify2FA());

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("currentpassword", password);

      const response = await axios.post(
        userApiUrl + `/${userId}/2fa_disable`,
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        dispatch(userDisable2FASuccessful());
        notify("saved", "2fa_disable_message");
      }
    } catch (error) {
      logError(error);
      dispatch(
        userDisable2FAFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};

export const verify2FA = (userId, email, password, secret, verification) => {
  return async (dispatch) => {
    dispatch(userVerify2FA());

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("currentpassword", password);
      formData.append("secret", secret);
      formData.append("verification", verification);

      const response = await axios.post(
        userApiUrl + `/${userId}/2fa_verify`,
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        dispatch(userVerify2FASuccessful());
        dispatch(userReset2FAData());
        notify("saved", "2fa_verify_message");
      }
    } catch (error) {
      logError(error);
      dispatch(
        userVerify2FAFailure(error.response.status, error.response.data.message)
      );
    }
  };
};

export const enable2FA = (userId, email) => {
  return async (dispatch) => {
    dispatch(userEnable2FA());

    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await axios.post(
        userApiUrl + `/${userId}/2fa_enable`,
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        const { secret, qrCode } = response.data;
        dispatch(userSave2FAData(secret, qrCode));
        dispatch(userEnable2FASuccessful());
      }
    } catch (error) {
      logError(error);
      dispatch(
        userEnable2FAFailure(error.response.status, error.response.data.message)
      );
    }
  };
};

const userCheck2FAEnabled = (status = null) => ({
  type: USER_CHECK_2FA_ENABLED,
  status,
});

const userCheck2FAFailure = (status, message) => ({
  type: USER_CHECK_2FA_FAILURE,
  status,
  message,
});

export const useHas2FAEnabled = () => {
  const dispatch = useDispatch();
  const check2FA = useCallback(
    async (userId) => {
      dispatch(userCheck2FAEnabled());

      try {
        const res = await axios.get(userApiUrl + `/${userId}/2fa_check`);
        if (res.status === 200) {
          dispatch(userCheck2FAEnabled(true));
        }
      } catch (e) {
        if (e.response.status === 403) {
          dispatch(userCheck2FAEnabled(false));
        }
        logError(e);
        dispatch(userCheck2FAFailure(e.response.status, e.response.message));
      }
    },
    [dispatch]
  );
  return check2FA;
};

export const userUpdateProfile = () => ({
  type: USER_UPDATE_PROFILE,
});

export const userUpdateProfileSuccessful = () => ({
  type: USER_UPDATE_PROFILE_SUCCESSFUL,
});

export const userUpdateProfileFailure = (code, message) => ({
  type: USER_UPDATE_PROFILE_FAILURE,
  code,
  message,
});

export const updateProfile = (userId, fields) => {
  return async (dispatch) => {
    dispatch(userUpdateProfile());

    try {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await axios.post(userApiUrl + `/${userId}`, formData, {
        credentials: "include",
      });
      if (response.status === 200) {
        dispatch(userUpdateProfileSuccessful());
        setTimeout(() => {
          dispatch(resetFormState());
        }, 3000);
      }
    } catch (error) {
      logError(error);
      dispatch(
        userUpdateProfileFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};
