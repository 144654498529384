import catalogApp from "../store/catalogApp";
import { USER_LOGOUT } from "../actions/root.actions";

const rootReducer = (history) => (state, action) => {
  const app = catalogApp(history);

  if (action.type === USER_LOGOUT) {
    return app(undefined, action);
  }
  return app(state, action);
};

export { rootReducer };
