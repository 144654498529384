import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    default: { key: "forms.validation.default.invalid" },
    required: { key: "forms.validation.default.required" },
  },
  string: {
    email: { key: "forms.validation.email.invalid" },
    min: ({ min }) => ({
      key: "forms.validation.string.too_short",
      values: { min },
    }),
    max: ({ max }) => ({
      key: "forms.validation.string.too_long",
      values: { max },
    }),
  },
});

export default Yup;
