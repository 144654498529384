// TODO: Disallow duplicate files
import * as React from "react";
import util from "util";
import { useDropzone } from "react-dropzone";
import "core-js/modules/es.array.from";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RequestFileList from "./RequestFileList";
import { formatFileSize } from "../../helpers/fileSizeFormatter";
import styles from "./file-uploader.module.scss";

// https://codesandbox.io/s/removable-drop-zone-82km9
const DropZone = ({
  multiple = true,
  onDrop,
  acceptedMimeTypes = [],
  acceptedExtensions = [],
  maxFiles = 1000,
  maxFileSize = 30000000,
}) => {
  const { t } = useTranslation();
  const [myFiles, setMyFiles] = React.useState([]);

  const onDropCallback = React.useCallback(
    (acceptedFiles) => {
      const files = [...myFiles, ...acceptedFiles];
      setMyFiles(files);
      onDrop(files);
    },
    [myFiles, onDrop]
  );

  if (!acceptedMimeTypes.length) {
    acceptedMimeTypes = [
      "application/msword", // .doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
      "application/vnd.ms-excel", // .xls
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-powerpoint", // .ppt
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
      "application/pdf",
      "text/plain",
      "application/vnd.ms-outlook", // .msg
    ];
  }
  if (!acceptedExtensions.length) {
    acceptedExtensions = [
      "msg",
      "txt",
      "pdf",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "docx",
      "doc",
    ];
  }

  const onDropRejected = (filesRejections) => {
    let errorMessage = `${t("max_file_size_exceeded")} ${formatFileSize(
      maxFileSize
    )}`;
    for (const rejection of filesRejections) {
      if (!acceptedMimeTypes.includes(rejection.file.type)) {
        errorMessage = util.format(
          t("accepted_filetypes"),
          acceptedExtensions.join(", ")
        );
        break;
      }
    }
    alert(`${errorMessage}`);
  };

  const isDisabled = myFiles.length >= maxFiles;

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: acceptedMimeTypes,
    noKeyboard: true,
    maxSize: maxFileSize,
    multiple,
    onDrop: onDropCallback,
    onDropRejected,
    disabled: isDisabled,
  });

  const handleRemoveFile = React.useCallback(
    (fileName) => {
      const dt = new DataTransfer();

      // Add selected fields to DataTransfer object
      for (const file of myFiles) {
        file.name !== fileName && dt.items.add(file); // Add only file name not matched files
      }

      inputRef.current.files = dt.files; // Overwrite files
      setMyFiles(Array.from(dt.files)); // Set states to render file list
    },
    [inputRef, myFiles]
  );

  const extraClasses = [styles.dropZone];
  if (isDisabled) {
    extraClasses.push(styles.dropZoneDisabled);
  }

  return (
    <section>
      <RequestFileList
        files={myFiles}
        handleRemoveFile={(fileName) => handleRemoveFile(fileName)}
      />

      <div {...getRootProps({ className: extraClasses.join(" ") })}>
        <input {...getInputProps()} />
        <p>
          {t("drop_attachments_or")}
          <br />
          <button type={"button"} className={"button"}>
            {t("browser_directory")}
          </button>
        </p>
        <p>
          <small>
            {util.format(
              t("accepted_filetypes"),
              acceptedExtensions.join(", ")
            )}
            <br />
            {t("max_file_size_is")} {formatFileSize(maxFileSize)}
          </small>
        </p>
      </div>
    </section>
  );
};

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptedMimeTypes: PropTypes.array,
  multiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
};

export default DropZone;
