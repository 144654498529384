import {
  transformData as transformDataHelper,
  transformNulls,
} from "./fetchHelper";

const arrayFields = ["biobanks"];

const nullToEmptyFields = [
  "start_date",
  "end_date",
  "intro",
  "thankyou",
  "project_number",
  "survey_url",
];

export const transformData = (data) => {
  const initialTransform = transformDataHelper(arrayFields, data, ",");
  return transformNulls(nullToEmptyFields, initialTransform);
};
