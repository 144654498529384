import React from "react";

import MainContainer from "../components/MainContainer";
import RequestPasswordReset from "../components/PasswordReset/RequestPasswordReset";
import UserHeader from "../components/User/UserHeader";
import { useTranslation } from "react-i18next";

const RequestPasswordResetPage = () => {
  const { t } = useTranslation();
  return (
    <div className="App page-user">
      <MainContainer>
        <div className="finbb-container">
          <UserHeader title={t("request_password_reset_form_title")} />
          <RequestPasswordReset />
        </div>
      </MainContainer>
    </div>
  );
};

export default RequestPasswordResetPage;
