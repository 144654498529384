import { useState } from "react";
import config from "../config";
import axios from "../helpers/axios";
import notify from "../helpers/notification";

const surveyApiUrl = `${config.fingeniousApiUrl}/survey`;
const notifyPublished = () =>
  notify("saved", "surveylist_survey_published_done");
const notifyUnpublished = () =>
  notify("saved", "surveylist_survey_unpublished_done");

const useToggleSurveyPublished = (surveyId, published) => {
  const [isPublished, setIsPublished] = useState(published);

  const doToggle = async (onSuccess) => {
    const newState = !isPublished;

    try {
      const formData = new FormData();
      formData.append("published", newState ? 1 : 0);

      const response = await axios.post(
        `${surveyApiUrl}/${surveyId}`,
        formData,
        {
          credentials: "include",
        }
      );
      if (response.status === 200) {
        setIsPublished(newState);
        if (newState) {
          notifyPublished();
        } else {
          notifyUnpublished();
        }
        onSuccess();
      }
    } catch (e) {}
  };

  return {
    doToggle,
    isPublished,
  };
};

export default useToggleSurveyPublished;
