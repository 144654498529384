import React from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CustomFormControl from "../CustomFormControl";
import { useTranslation } from "react-i18next";
import ChangePasswordSchema from "./ChangePasswordSchema";

import { changePassword } from "../../actions/users";
import Alert from "react-bootstrap/Alert";

const PasswordResetFormControl = (props) => (
  <CustomFormControl {...props} validationSchema={ChangePasswordSchema} />
);

const PasswordResetSubmit = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <button disabled={disabled} className="button button-block" type="submit">
      {t("change_password_form_label_submit")}
    </button>
  );
};

const ChangePassword = ({ token }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorCode = useSelector(
    (state) => state.forms.changePassword.errorCode
  );
  const success = useSelector((state) => state.forms.changePassword.success);

  if (success) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: t("change_password_form_done_message", {
            url: "/user/login",
          }),
        }}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={{
          password: "",
          confirmpassword: "",
          psw_reset_string: token,
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values) => {
          dispatch(changePassword(values.psw_reset_string, values.password));
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {errorCode && (
              <Alert variant="danger">{t("change_password_form_error")}</Alert>
            )}
            <Row>
              <Col lg={12}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formPassword">
                    <PasswordResetFormControl
                      name="password"
                      label={t(`change_password_form_label_password`)}
                      type="password"
                      required={true}
                    />
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col lg={12}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formConfirmPassword">
                    <PasswordResetFormControl
                      name="confirmpassword"
                      label={t(`change_password_form_label_confirmpassword`)}
                      type="password"
                      required={true}
                    />
                  </Form.Group>
                </Form.Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <PasswordResetSubmit disabled={isSubmitting} />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
