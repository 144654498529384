import React from "react";

import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import Title from "../../components/Title/Title";
import SurveyUploadSubjects from "../../components/SurveyUploadSubjects/SurveyUploadSubjects";
import { useTranslation } from "react-i18next";

const SurveyUploadSubjectsPage = ({ match }) => {
  const { t } = useTranslation();
  const surveyId = parseInt(match.params.surveyId);
  return (
    <div className="App page-survey-upload">
      <Title>{t("upload_subjects_page_title")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <SurveyUploadSubjects surveyId={surveyId} />
    </div>
  );
};

export default SurveyUploadSubjectsPage;
