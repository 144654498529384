import { useDispatch } from "react-redux";
import LanguageChangeControl from "./LanguageChangeControl";
import { changeLanguage } from "../actions/i18n";
import React from "react";
import { useTranslation } from "react-i18next";

const invertLanguage = (lng) => (lng === "en" ? "fi" : "en");

const LanguageChangeControlContainer = () => {
  const dispatch = useDispatch();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <LanguageChangeControl
      currentLanguage={language}
      onClick={() => dispatch(changeLanguage(invertLanguage(language)))}
    />
  );
};

export default LanguageChangeControlContainer;
