import React from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import CustomFormControl from "../CustomFormControl";
import { useTranslation } from "react-i18next";
import RequestPasswordSchema from "./RequestPasswordSchema";

import { requestPasswordReset } from "../../actions/users";

const PasswordResetFormControl = (props) => (
  <CustomFormControl {...props} validationSchema={RequestPasswordSchema} />
);

const PasswordResetSubmit = () => {
  const { t } = useTranslation();
  return (
    <button className="button button-block" type="submit">
      {t("request_password_form_label_submit")}
    </button>
  );
};

const RequestPasswordReset = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorCode = useSelector(
    (state) => state.forms.requestPasswordReset.errorCode
  );
  const success = useSelector(
    (state) => state.forms.requestPasswordReset.success
  );

  if (success) {
    return <p>{t("request_password_form_done_message")}</p>;
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={RequestPasswordSchema}
        onSubmit={(values) => {
          dispatch(requestPasswordReset(values.email));
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {errorCode && (
              <Alert variant="danger">{t("request_password_form_error")}</Alert>
            )}
            <Row>
              <Col lg={12}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formEmail">
                    <PasswordResetFormControl
                      name="email"
                      label={t(`request_password_form_label_email`)}
                      type="text"
                      required={true}
                    />
                  </Form.Group>
                </Form.Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <PasswordResetSubmit />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestPasswordReset;
