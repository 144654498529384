import config from "../config";
import useFetch from "./useFetch";

const useFetchSurveyUsers = (surveyId) => {
  const {
    data,
    loading,
    error,
    refresh: refreshFetch,
  } = useFetch(`${config.fingeniousApiUrl}/survey/${surveyId}/user`);

  return {
    users: data,
    loading,
    error,
    refresh: refreshFetch,
  };
};

export default useFetchSurveyUsers;
