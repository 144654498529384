import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStaticUrl } from "../../helpers/linkGenerator";

export const privacyPolicyUrl = `${getStaticUrl()}/privacy-policy`;
export const privacyPolicyUrlPreLogin = "/privacy-policy";

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();

  const lang = useMemo(() => i18n.language, [i18n.language]);

  return (
    <div class="finbb-container">
      {lang === "en" ? <PrivacyPolicyEnglish /> : <PrivacyPolicyFinnish />}
    </div>
  );
};

const PrivacyPolicyEnglish = (props) => {
  return (
    <>
      <h1>MYBIOBANK PRIVACY STATEMENT</h1>
      <p>
        <i>23.5.2024</i>
      </p>

      <p>
        We are committed to respecting and safeguarding the privacy of the users
        of the MyBiobank service. The MyBiobank service is a biobank service for
        users who have given their biobank consent so that they can choose to
        participate electronically in different research projects, and for
        researchers for collecting research data. The service is provided by the
        Finnish Biobank Cooperative – FINBB in collaboration with biobanks.
      </p>

      <h2>Controller</h2>
      <p>
        The controller of personal data files is the biobank organisation, or
        biobank organisations, for which the user has given a biobank consent,
        which has invited the user to participate in the service, or for which
        the user gives their consent and contact details for the purpose of
        using e-services through the service. The biobank organisations and
        links to their homepages can be found from
        <a href="https://finbb.fi/fi/member-biobanks">
          https://finbb.fi/fi/member-biobanks
        </a>
        . The biobanks’ privacy statements are on their homepages.{" "}
      </p>

      <p>
        If a sample donor participates in a research project through the
        service, the controller of the research data will be separately
        indicated in the service prior to the participation
      </p>
      <p>
        With regard to measures to ensure the usability of the MyBiobank
        service, the controller of personal data file is the Finnish Biobank
        Cooperative – FINBB, c/o Regus, Yliopistonkatu 31, FI- 20100 Turku,
        Finland, business ID 2853268-2. FINBB’s data protection officer can be
        reached by e- mail at{" "}
        <a href="mailto:tietosuojavastaava@finbb.fi">
          tietosuojavastaava@finbb.fi
        </a>{" "}
      </p>

      <h2>Reasons for processing personal data</h2>
      <p>
        The reasons for processing personal data are indicated in the biobanks’
        privacy statements, which can be found from the biobanks’ homepages, and
        in the information on a research project that is presented prior to any
        participation in the research project.{" "}
      </p>
      <p>
        The Finnish Biobank Cooperative – FINBB may also process personal data
        to ensure the functionality of the service, for example, to prevent
        misuse or to investigate cases of suspected misuse, and to respond to
        potential claims. In this regard, the right to process personal data is
        based on Article 6(1)(f) of the EU’s General Data Protection Regulation:
        processing is necessary for the purposes of the legitimate interests
        pursued by the controller or by a third party.
      </p>

      <h2>Collection of personal data</h2>
      <p>
        Information collected on the user following registration with the
        service:
      </p>
      <ul>
        <li>
          In connection with the user’s authentication, their personal identity
          code is pseudonymized: the identity code is replaced with a secure
          code, which cannot be directly linked with the original code. This
          pseudocode will then be used in the research project; otherwise, no
          personal identity codes or other identification data will be processed
          in the system
        </li>
        <li>E-mail address, optional</li>
        <li>Telephone number, optional</li>
        <li>Consent to electronic services, optional</li>
        <li>Consent to participate in the research project, optional </li>
        <li>
          Consent to inform health care of research findings relevant to the
          participant’s health, optional
        </li>
      </ul>
      <p>
        Pseudonymised questionnaire responses of those who gave their consent
        to, and participated in, the research project will be stored directly in
        the systems used by the research project, to which the participants have
        transferred via links form MyBiobank. This information will not be
        stored in MyBiobank.
      </p>

      <h2>Disclosure of personal data</h2>
      <p>
        No personal data collected in the service is regularly disclosed to
        outside parties. The data collected in the service may be transferred or
        copied to another environment; information on the consent to electronic
        services, for example, as well as contact details will be stored in the
        consent register of the biobank organisation.{" "}
      </p>
      <p>
        As to the data processing carried out in biobanks, information on
        potential disclosure is provided in the biobank’s privacy statement.
      </p>
      <p>
        Any regular disclosures of research data are indicated in the
        information concerning the research project prior to any participation
        in the research project.
      </p>

      <h2>Retention of Personal Data</h2>
      <p>
        We will retain your personal data for as long as they are needed for the
        purpose they were collected. The necessity of the data will be assessed
        on a regular basis.{" "}
      </p>
      <p>
        Authentication with the MyBiobank service is necessary to be able to use
        the service. Authentication takes place via the Suomi.fi service. The
        data in the register of the Suomi.fi online service will be retained for
        the duration of the authentication session only. For further information
        about the processing of personal data in the suomi.fi service can be
        found at <a href="https://www.suomi.fi/">www.suomi.fi</a>.{" "}
      </p>

      <h2>Rights of the data subject</h2>
      <p>
        A data subject – an individual whose personal data we process – has the
        right, as described in greater detail in the EU’s General Data
        Protection Regulation and in the Finnish Data Protection Act, to:
      </p>
      <ul>
        <li>be informed about the processing of their personal data;</li>
        <li>gain access to their personal data;</li>
        <li>have their personal data rectified;</li>
        <li>have their personal data erased;</li>
        <li>restrict the processing of their data;</li>
        <li>object to the processing of their data;</li>
        <li>have their data transferred to another system.</li>
      </ul>

      <p>
        Each data subject also has the right to file a complaint with a
        supervisory authority: The Office of the Data Protection Ombudsman,
        Ratapihantie 9, 6th floor, FI-00520 Helsinki, Finland, tietosuoja@om.fi.
      </p>
      <p>
        The provision of personal data is a prerequisite for the use of the
        system.
      </p>
      <p>The Finnish supervisory authority is: </p>
      <p>Office of the data protection ombudsman</p>
      <p>Visiting address: Ratapihantie 9, 00520 Helsinki, Finland</p>
      <p>Postal address: P.O. Box 800, 00521 Helsinki, Finland</p>
      <p>tietosuoja@om.fi.</p>

      <p>Providing personal data is required for using the service.</p>

      <h4>Service provider</h4>
      <div className="static-block">
        <div>
          <strong className="static-service-detail">Name:</strong>Finnish
          Biobank Cooperative – FINBB
        </div>
        <div>
          <strong className="static-service-detail">Registered office:</strong>
          Turku, Finland
        </div>
        <div>
          <strong className="static-service-detail">Address:</strong>c/o Regus,
          Yliopistonkatu 31, FI-20100 Turku, Finland
        </div>
        <div>
          <strong className="static-service-detail">Business ID:</strong>
          2853268-2
        </div>
      </div>
    </>
  );
};

const PrivacyPolicyFinnish = () => {
  return (
    <>
      <h1>OMABIOPANKKI TIETOSUOJASELOSTE</h1>
      <p>
        <i>22.5.2024</i>
      </p>

      <p>
        Olemme sitoutuneet kunnioittamaan ja turvaamaan Omabiopankki-palvelun
        käyttäjien yksityisyyttä. Omabiopankki-palvelu on biopankkien palvelu
        biopankkisuostumuksen antaneille käyttäjille, jotta he voivat
        halutessaan osallistua sähköisesti eri tutkimushankkeisiin ja
        tutkijoille tutkimustietojen keräämiseksi. Palvelun toteuttaa
        Biopankkien Osuuskunta Suomi – FINBB yhteistyössä biopankkien kanssa.{" "}
      </p>

      <h2>Rekisterinpitäjä</h2>
      <p>
        Henkilötietojen rekisterinpitäjä on se biopankkiorganisaatio tai ne
        biopankkiorganisaatiot, johon/joihin käyttäjä on antanut
        biopankkisuostumuksen, joka on kutsunut käyttäjän osallistumaan
        palveluun, tai jolle/joille käyttäjä antaa suostumuksen ja yhteystiedot
        sähköiseen asiointiin palvelun kautta. Biopankkiorganisaatiot ja linkit
        niiden kotisivuille löytyvät osoitteesta
        <a href="https://finbb.fi/fi/member-biobanks">
          https://finbb.fi/fi/member-biobanks
        </a>
        . Kunkin biopankin omat tietosuojailmoitukset löytyvät niiden
        kotisivuilta.{" "}
      </p>
      <p>
        Mikäli näytteenantaja osallistuu palvelun kautta tutkimushankkeeseen,
        tutkimusaineiston rekisterinpitäjä ilmoitetaan palvelussa erikseen ennen
        tutkimukseen osallistumista.
      </p>
      <p>
        OmaBiopankki-palvelun käytettävyyden varmistamiseen liittyvien
        toimenpiteiden osalta henkilötietojen rekisterinpitäjänä on Biopankkien
        Osuuskunta Suomi – FINBB, c/o Regus, Yliopistonkatu 31, 20100 Turku,
        y-tunnus 2853268-2. FINBB:n tietosuojavastaavan tavoittaa sähköpostitse
        osoitteesta{" "}
        <a href="mailto:tietosuojavastaava@finbb.fi">
          tietosuojavastaava@finbb.fi
        </a>
        .
      </p>

      <h2>Käsittelyperusteet</h2>
      <p>
        Käsittelyperusteet ilmenevät biopankkien tietosuojaselosteista, jotka
        löytyvät biopankkien kotisivuilta, ja tutkimushankkeiden tiedoista,
        jotka esitetään ennen tutkimukseen osallistumista.{" "}
      </p>
      <p>
        Biopankkien Osuuskunta Suomi – FINBB voi käsitellä henkilötietoja myös
        palvelun toimivuuden varmistamiseksi, kuten esimerkiksi väärinkäytön
        estämiseksi tai väärinkäyttöepäilyjen tutkimiseksi, sekä mahdollisiin
        vaateisiin vastaamiseksi. Näiltä osin oikeus henkilötietojen käsittelyyn
        perustuu EU:n yleisen tietosuoja-asetuksen 6 artiklan 1 kohdan f
        alakohtaan: käsittely on tarpeen rekisterinpitäjän tai kolmannen
        osapuolen oikeutettujen etujen toteuttamiseksi.
      </p>

      <h2>Henkilötietojen kerääminen</h2>

      <p>
        Palveluun rekisteröitymisen jälkeen käyttäjästä kerättävät
        henkilötiedot:
      </p>
      <ul>
        <li>
          Käyttäjän tunnistautumisen yhteydessä hänen henkilötunnuksensa
          pseudonymisoidaan, eli siitä muodostetaan tietoturvallisesti koodi,
          jota ei enää voi suoraan yhdistää henkilötunnukseen, ja jota käytetään
          tutkimuksessa henkilötietojen suojaamiseksi; muutoin järjestelmässä ei
          käsitellä henkilötunnuksia tai muita tunnistetietoja
        </li>
        <li>Sähköpostiosoite, vapaaehtoinen</li>
        <li>Puhelinnumero, vapaaehtoinen</li>
        <li>Sähköisen asioinnin suostumus, vapaaehtoinen</li>
        <li>Suostumus tutkimushankkeeseen osallistumiseen, vapaaehtoinen</li>
        <li>
          Hyväksyntä biopankille viedä tutkimukseen osallistuneen
          tutkimustietoja terveydenhuoltoon, vapaaehtoinen
        </li>
      </ul>
      <p>
        Tutkimushankkeeseen suostumuksensa antaneen ja siihen osallistuva
        henkilön pseudonymisoidut kyselytutkimusvastaukset tallentuvat suoraan
        kunkin tutkimushankkeen käyttämään järjestelmään, johon käyttäjä on
        siirtynyt OmaBiopankista linkin kautta. Nämä tiedot eivät tallennu
        OmaBiopankkiin.
      </p>

      <h2>Henkilötietojen luovutus</h2>
      <p>
        Palvelussa kerättäviä henkilötietoja ei säännönmukaisesti luovuteta
        ulkopuolisille. Palvelussa kerättävä tieto voidaan siirtää tai kopioida
        toiseen ympäristöön, esimerkiksi tiedot sähköisen asioinnin
        suostumuksesta ja yhteystiedoista tallennetaan biopankkiorganisaation
        suostumusrekisteriin.
      </p>
      <p>
        Biopankeissa tapahtuvan tietojenkäsittelyn osalta tieto mahdollisista
        luovutuksista on biopankin tietosuojaselosteessa.
      </p>
      <p>
        Tutkimusaineiston mahdollisista säännönmukaisista luovutuksista
        ilmoitetaan tutkimushankkeen tiedoissa ennen tutkimukseen
        osallistumista.
      </p>

      <h2>Henkilötietojen säilytys</h2>
      <p>
        Säilytämme tietojasi niin kauan kuin niitä tarvitaan siihen
        tarkoitukseen, jota varten ne on kerätty. Tietojen tarpeellisuus
        arvioidaan säännöllisesti.
      </p>
      <p>
        Omabiopankki-palveluun tunnistautuminen on sen käytön kannalta
        välttämätöntä. Tunnistautuminen tehdään Suomi.fi-palvelun kautta.
        Suomi.fi-verkkopalvelun rekisterin tietoja säilytetään vain
        tunnistautumisistunnon ajan. Lisätietoja suomi.fi henkilötietojen
        käsittelystä löytyy osoitteesta{" "}
        <a href="https://www.suomi.fi/">www.suomi.fi</a>.
      </p>

      <h2>Rekisteröidyn oikeudet</h2>
      <p>
        Rekisteröidyllä, eli henkilöllä, jonka henkilötietoja käsittelemme, on
        EU:n ja Suomen tietosuojalaissa tarkemmin kuvatusti oikeus:
      </p>
      <ul>
        <li>saada tietoa henkilötietojensa käsittelystä</li>
        <li>saada pääsy henkilötietoihin</li>
        <li>tietojen oikaisemiseen</li>
        <li>tietojen poistamiseen</li>
        <li>tietojen käsittelyn rajoittamiseen </li>
        <li>vastustaa tietojen käsittelyä</li>
        <li>siirtää tiedot järjestelmästä toiseen</li>
      </ul>
      <p>
        Jokaisella rekisteröidyllä oikeus tehdä valitus valvontaviranomaiselle:
        Tietosuojavaltuutetun toimisto, Ratapihantie 9, 6.krs, 00520 Helsinki,
        tietosuoja@om.fi.
      </p>
      <p>Henkilötietojen antaminen on järjestelmän käytön edellytys.</p>

      <h4>Palvelun tarjoaja</h4>
      <div className="static-block">
        <div>
          <strong className="static-service-detail">Nimi:</strong>FINBB –
          Biopankkien Osuuskunta Suomi
        </div>
        <div>
          <strong className="static-service-detail"> Kotipaikka:</strong>
          Turku
        </div>
        <div>
          <strong className="static-service-detail">Osoite:</strong>c/o Regus,
          Yliopistonkatu 31, FI-20100 Turku, Finland
        </div>
        <div>
          <strong className="static-service-detail">Y-tunnus:</strong>
          2853268-2
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
