import React from "react";

import MainContainer from "../components/MainContainer";
import Register from "../components/Register/Register";
import { useTranslation } from "react-i18next";
import UserHeader from "../components/User/UserHeader";

const RegisterPage = () => {
  const { t } = useTranslation();
  return (
    <div className="App page-user">
      <MainContainer>
        <div className="finbb-container">
          <UserHeader title={t("register_form_title")} />
          <Register />
        </div>
      </MainContainer>
    </div>
  );
};

export default RegisterPage;
