import React, { useEffect } from "react";
import { removeToken } from "../helpers/session";
import { replace } from "connected-react-router";
import { useDispatch } from "react-redux";
import { logout } from "../actions/root";

const LogoutPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    removeToken();
    dispatch(logout());
    dispatch(replace("/user/login"));
  }, [dispatch]);
  return <></>;
};

export default LogoutPage;
