import LoginPage from "../pages/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import RegisterPage from "../pages/RegisterPage";
import RequestPasswordResetPage from "../pages/RequestPasswordResetPage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import EditProfilePage from "../pages/EditProfilePage";
import Verify2FAPage from "../pages/Verify2FAPage";

const routes = [
  { path: "/user/login", private: false, exact: true, component: LoginPage },
  { path: "/user/logout", private: true, exact: true, component: LogoutPage },
  {
    path: "/user/register",
    private: false,
    exact: true,
    component: RegisterPage,
  },
  {
    path: "/user/request-password-reset",
    private: false,
    exact: true,
    component: RequestPasswordResetPage,
  },
  {
    path: "/user/do-password-reset/:token",
    private: false,
    exact: true,
    component: ChangePasswordPage,
  },
  {
    path: "/user/edit-profile",
    private: true,
    exact: true,
    component: EditProfilePage,
  },
  {
    path: "/user/2factor-authentication",
    private: true,
    exact: true,
    component: Verify2FAPage,
  },
];

export default routes;
