import { useField } from "formik";
import Form from "react-bootstrap/Form";
import React, { useMemo } from "react";
import { isRequiredField, existsInSchema } from "../helpers/formHelper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CustomFormControl = ({
  label,
  validationSchema,
  finalValidationSchema = null,
  enableValidation = true,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  const schemaForRequiredLabel =
    finalValidationSchema !== null ? finalValidationSchema : validationSchema;

  const isRequiredLabel = useMemo(
    () => isRequiredField(schemaForRequiredLabel, props.name),
    [schemaForRequiredLabel, props.name]
  );
  const isRequiredHtml5 = useMemo(
    () => isRequiredField(validationSchema, props.name),
    [validationSchema, props.name]
  );

  const itExistsInSchema = useMemo(
    () => existsInSchema(validationSchema, props.name),
    [validationSchema, props.name]
  );

  return (
    <>
      {label && (
        <Form.Label>
          {label} {isRequiredLabel ? "*" : ""}
        </Form.Label>
      )}
      <Form.Control
        {...field}
        {...props}
        isInvalid={enableValidation && itExistsInSchema && touched && error}
        isValid={enableValidation && itExistsInSchema && touched && !error}
        required={isRequiredHtml5}
      />
      {touched && error && (
        <Form.Control.Feedback type="invalid">
          {t(error.key, error.values)}
        </Form.Control.Feedback>
      )}
    </>
  );
};

CustomFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default CustomFormControl;
