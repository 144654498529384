import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStaticUrl } from "../../helpers/linkGenerator";
import logo from "../../assets/img/finbb_logo_green.png";

export const termsAndConditionsUrl = `${getStaticUrl()}/temrs-and-conditions`;
export const termsAndConditionsUrlPreLogin = "/terms-and-conditions";

const TermsAndConditions = () => {
  const { i18n } = useTranslation();

  const lang = useMemo(() => i18n.language, [i18n.language]);

  return (
    <div class="finbb-container">
      <img className="mta-logo" src={logo} alt="logo" />

      {lang === "en" ? <TermsEnglish /> : <TermsFinnish />}

      <div className="mta-footer">
        <div>
          <span>Finnish Biobank Cooperative – FINBB</span>
          <span>Y-tunnus 2853268-2</span>
        </div>
        <div>
          <span>Regus, Ruukinkatu 2-4, 20540 Turku</span>
          <span>FI37 5716 9020 0605 34, OKOYFIHH</span>
        </div>
      </div>
    </div>
  );
};

const TermsEnglish = () => {
  return (
    <>
      <h1>Fingenious® website terms of use</h1>

      <p>
        These website terms of use ("terms of use") govern your access to and
        use of the Fingenious website and the Finnish Biobank Cooperative –
        FINBB’s (from here on “FINBB) digital services available through the
        website (websites and services together the “Services”). If you do not
        agree to all of these terms of use, do not access or use the Services.
        By accessing or using the Services, you and the entity you are
        authorized to represent ("you" or "your") signify your agreement to be
        bound by these terms of use. There may be additional terms or agreements
        specific to certain Services, in which case those terms or agreements
        will prevail, and these terms of use will be complementary.
      </p>

      <p>
        FINBB retains the right to make changes and discontinue its Services and
        any content therein without notice and to prevent access to them at any
        time.
      </p>

      <h2>Copyright and other intellectual property rights</h2>

      <p>
        FINBB and its licensors reserve all rights to the Services (including
        their visual image and published content).
      </p>

      <p>
        You may browse and use the content in the Services only for the purposes
        of scientific research, including planning and preparation and
        performing the actual research. You may not sell or distribute any
        content or copies thereof in any form for any commercial purpose or
        modify them or link them to other content or websites.
      </p>

      <p>
        Using Services may require that you disclose certain information. If you
        send, upload or otherwise provide any information to the Services, you
        must first ensure that you only provide information that is necessary
        for the Service and that you have the necessary rights to the
        information. By providing information you grant FINBB all rights
        necessary for it to be able to process the information to provide the
        Services. FINBB assumes no liability for any information you provide and
        may delete them at any time at its option.
      </p>

      <h2>Liability for content</h2>

      <p>
        FINBB will always try to ensure that the Services are up to date and
        accurate, but we disclaim all liabilities in relation to the accuracy of
        the Services and t heir compatibility with your specific needs and in
        relation to any actions you may take based on the Services.
      </p>

      <p>
        FINBB does not warrant faultless or continuous operation of the Services
        and will not be liable for any indirect or direct damage attributable to
        the use of the Services or the inability to use them.
      </p>

      <h2>Using Services</h2>

      <p>
        The Services require registration and authentication. Certain Services
        may be subject to the payment of fees or contain other features that
        require a separate service agreement between you and FINBB. Using the
        Services or other products or services of FINBB, its members or
        collaborators may require additional terms, agreements or permissions.
      </p>
    </>
  );
};

const TermsFinnish = () => {
  return (
    <>
      <h1>Fingenious® - verkkosivuston yleiset käyttöehdot</h1>

      <p>
        Käyttämällä Biopankkien Osuuskunta Suomi – FINBB:n (jäljempänä ”FINBB”)
        Fingenious -palvelun verkkosivuja ja niillä olevia palveluita (sivusto
        ja palvelut yhdessä jäljempänä ”Palvelut”) sitoudut noudattamaan näitä
        käyttöehtoja. Palveluiden käyttö edellyttää, että hyväksyt nämä
        käyttöehdot kokonaisuudessaan. Ellet hyväksy, sinun tulee lopettaa
        Palveluiden käyttö. Palveluihin voi liittyä myös erillisiä
        palvelukohtaisia käyttöehtoja tai sopimuksia. Tällöin näitä yleisiä
        käyttöehtoja sovelletaan toissijaisesti.
      </p>

      <p>
        FINBB:llä on oikeus muuttaa Palveluitaan, verkkosivustojaan ja niiden
        sisältöä sekä sivun käyttöehtoja ilman ennakkoilmoitusta ja oikeus estää
        pääsy verkkosivustoille koska tahansa.
      </p>

      <h2>Tekijänoikeus ja muut immateriaalioikeudet</h2>

      <p>
        Kaikki Palveluihin (mukaan lukien myös niiden visuaalinen ilme ja
        sivuillamme julkaistava aineisto) liittyvät oikeudet kuten tekijänoikeus
        ja kaikki muut immateriaalioikeudet kuuluvat FINBB:lle tai sen
        lisenssinantajille.
      </p>

      <p>
        Palveluissa julkaistavaa aineistoa saa selata ja käyttää vain
        tieteelliseen tutkimustarkoitukseen, eli tieteellisen tutkimuksen
        valmistelemiseksi tai toteuttamiseksi. Aineistoa tai sen jäljennöksiä ei
        saa miltään osin myydä tai jakaa sähköisesti tai paperikopioina
        kaupallisessa tarkoituksessa eikä muuttaa tai liittää muuhun aineistoon
        tai muihin Internet-sivuihin.
      </p>

      <p>
        Palveluiden käyttäminen saattaa edellyttää sinulta tietoja. Mikäli
        lähetät, lataat tai muulla tavalla toimitat Palveluihin tietoja, sinun
        tulee varmistua siitä, että toimitat vain Palvelun toteuttamiseksi
        välttämättömiä tietoja ja että sinulla on niihin tarvittavat oikeudet.
        Toimittamalla tietoja myönnät samalla FINBB:lle kaikki tarvittavat
        oikeudet, jotta se voi käsitellä tietoja Palveluiden tarjoamiseksi.
        FINBB ei ole vastuussa toimittamistasi tiedoista ja voi vapaasti poistaa
        ne harkintansa mukaan.
      </p>

      <h2>Vastuu Palveluidemme sisällöstä</h2>

      <p>
        FINBB tekee parhaansa, jotta Palveluiden tiedot olisivat ajan tasalla ja
        pitäisivät paikkaansa, mutta emme vastaa tietojen oikeellisuudesta,
        soveltuvuudesta tarpeisiisi emmekä tietojen perustella tehtyjen toimien
        tuloksesta.
      </p>

      <p>
        FINBB ei takaa Palveluiden virheetöntä ja keskeytyksetöntä käyttöä eikä
        vastaa Palveluiden käytöstä mahdollisesti aiheutuneista välillisistä tai
        välittömistä vahingoista.
      </p>

      <p>
        Palveluiden käyttöPalveluiden käyttö edellyttää rekisteröitymistä ja
        tunnistautumista. Palvelut voivat sisältää maksullisia osioita tai muita
        sellaisia osioita, joiden käyttäminen edellyttää erillisen
        asiakassopimuksen tekemistä FINBB:n kanssa. FINBB:n jäsenten tai muiden
        FINBB:n edustamien tahojen tuotteiden ja palveluiden käyttäminen saattaa
        lisäksi edellyttää erillistä sopimusta, päätöstä tai erillisten
        käyttöehtojen hyväksymistä.
      </p>
    </>
  );
};

export default TermsAndConditions;
